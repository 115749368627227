import { zodResolver } from "@hookform/resolvers/zod";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import { useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { FullyRegisteredUserType, fullyRegisteredUserSchema } from "../../../../../../../sharedEntities";
import { ReactionType, storedReactionSchema, unstoredReactionSchema } from "../../../../../../../sharedEntities/reactionType";
import { UseCurrentGroupId } from "../../../../../../globalHook/useCurrentGroupId";
import { firestoreConverter } from "../../../../../../util/firestoreConverter";
import { UseFirestoreInitialization } from "../../../../../../globalHook/UseFirestoreInitialization";
import { FeedItemPresenter } from "./FeedItemPresenter";
import { FormType, zodSchema } from "./type";
import { FeedItemData } from "../type";
import { UseUserInfo } from "../../../../../../globalHook/UseUserInfo";

export function FeedItemContainer(feedItemData: FeedItemData) {
  const modalRef = useRef<HTMLIonModalElement>(null);

  const { data: currentGroupId } = UseCurrentGroupId();
  const queryClient = useQueryClient();

  const rhfMethods = useForm<FormType>({
    resolver: zodResolver(zodSchema),
  });

  const { data: userInfo } = UseUserInfo();
  const { data: db } = UseFirestoreInitialization();

  const { mutate } = useMutation({
    mutationFn: async (formData: { reactionEmoji: string }) => {
      console.log("on mutate");
      if (!userInfo) throw new Error("currentUser error");
      if (!db) throw new Error("db error");
      if (!currentGroupId) throw new Error("groupId error");

      const reactionCollectionRef = collection(
        db,
        "version/v1/groups",
        currentGroupId,
        "posts",
        feedItemData.postId,
        "reactions"
      ).withConverter(firestoreConverter(unstoredReactionSchema));

      const sameReactionQuery = query(
        reactionCollectionRef,
        where("reactionEmoji", "==", formData.reactionEmoji)
      );
      const queryResult = await getDocs(sameReactionQuery);

      if (queryResult.empty) {
        return await addDoc(reactionCollectionRef, {
          reactionEmoji: formData.reactionEmoji,
          members: [doc(db, "version/v1/users", userInfo.uid)],
        });
      }

      const doesDocExist = queryResult.docs[0].exists();
      if (doesDocExist === false) {
        return await addDoc(reactionCollectionRef, {
          reactionEmoji: formData.reactionEmoji,
          members: [doc(db, "version/v1/users", userInfo.uid)],
        });
      }

      const reactionDocData = queryResult.docs[0].data();
      const reactionDocRef = queryResult.docs[0].ref;
      const memberIds = reactionDocData.members.map((item) => item.id);
      const doesCurrentUserExistInMembers = memberIds.includes(userInfo.uid);
      const currentUserDocRef = doc(
        db,
        "version/v1/users",
        userInfo.uid
      ).withConverter(firestoreConverter(fullyRegisteredUserSchema));

      if (doesCurrentUserExistInMembers) return true;
      return await updateDoc(reactionDocRef, {
        members: arrayUnion(currentUserDocRef),
      });
    },
  });

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === "emojiSelect") {
      rhfMethods.setValue("reactionEmoji", ev.detail.data);
      rhfMethods.handleSubmit(onSubmitFunction)();
    }
  }
  const openModal = () => {
    modalRef.current?.present();
  };
  const closeModal = () => {
    modalRef.current?.dismiss();
  };

  function onEmojiSelect(data: any, event: any) {
    modalRef.current?.dismiss(data.native, "emojiSelect");
  }

  const onSubmitFunction: SubmitHandler<FormType> = (data, e) => {
    console.log("onSubmitFunction");
    e?.preventDefault();
    mutate(data);
  };

  // if (!currentGroupId) return null;
  return (
    <FeedItemPresenter
      {...feedItemData}
      openModal={openModal}
      closeModal={closeModal}
      onEmojiSelect={onEmojiSelect}
      rhfMethods={rhfMethods}
      modalRef={modalRef}
      onSubmitFunction={onSubmitFunction}
      onWillDismiss={onWillDismiss}
    />
  );
}
