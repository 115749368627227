import { useMutation } from "@tanstack/react-query";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { z } from "zod";

const createAccountSchema = z.object({
    email: z.string({ required_error: "email is required" }).email(),
    password: z
        .string({ required_error: "password is required" })
        .min(8, "min length 8"),
});

type CreateAccountFormInputs = z.infer<typeof createAccountSchema>;

export const UseFirebaseAuthSignIn = () => {
    const signInFunction = async (createAccountFormInputs: CreateAccountFormInputs) => {
        const auth = getAuth()
        const email = createAccountFormInputs.email
        const password = createAccountFormInputs.password
        return signInWithEmailAndPassword(auth, email, password)
    }

    return useMutation({ mutationFn: signInFunction })
}