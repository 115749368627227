import { getAuth } from "firebase/auth";
import { UseFirestoreInitialization } from "../../../../../../../globalHook/UseFirestoreInitialization";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { firestoreConverter } from "../../../../../../../util/firestoreConverter";
import { fullyRegisteredUserSchema, storedGroupSchema } from "../../../../../../../../sharedEntities";
import { useMutation } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { UseUserInfo } from "../../../../../../../globalHook/UseUserInfo";

export function UseJoinGroupRequest() {
    const { data: userInfo } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()

    async function groupRegistration(formData: { groupId: string, invitationCode: string }) {
        const auth = getAuth()
        if (!auth) throw new Error("auth error")
        if (!userInfo) throw new Error("userInfo error")
        if (!db) throw new Error("firestore error")
        if (!formData.groupId || !formData.invitationCode) throw new Error("groupId or InvitationCode is not valid")
        const groupDocRef = doc(db, "version/v1/groups", formData.groupId).withConverter(firestoreConverter(storedGroupSchema))
        const groupDoc = await getDoc(groupDocRef)
        if (!groupDoc.exists()) throw new Error("group does not exist")

        const userDocRef = doc(db, "version/v1/users", userInfo.uid).withConverter(firestoreConverter(fullyRegisteredUserSchema))
        const userDoc = await getDoc(userDocRef)
        if (!userDoc.exists()) throw new Error("user does not exist")

        try {
            await updateDoc(userDocRef, { groups: arrayUnion(groupDocRef) }),
                await updateDoc(groupDocRef, { pendingMember: arrayUnion(userDocRef) })
            return true
        } catch (error) {
            if (error instanceof Error || error instanceof FirebaseError) throw error
            throw new Error("unknown error")
        }


    }


    return useMutation({ mutationFn: groupRegistration })

}