import { useQueryClient, useQuery } from "@tanstack/react-query";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { useState } from "react";
import { ReactionType, storedReactionSchema } from "../../../../../../../../sharedEntities/reactionType";
import { firestoreConverter } from "../../../../../../../util/firestoreConverter";
import { UseFirestoreInitialization } from "../../../../../../../globalHook/UseFirestoreInitialization";
import { ReactionsType } from "./type";
import { CommentType } from "../../../../../../../../sharedEntities/commentType";
import { UseCurrentGroupId } from "../../../../../../../globalHook/useCurrentGroupId";
import { UseCurrentPostId } from "../../../../../../../globalHook/useCurrentPostId";
import { UseUserInfo } from "../../../../../../../globalHook/UseUserInfo";


export function UseCommentReactions(commentId: string) {
    const queryClient = useQueryClient()
    const { data: currentUser } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: currentPostId } = UseCurrentPostId()

    async function getReactions(): Promise<ReactionsType> {
        if (!currentUser) throw new Error("currentUser error")
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')
        if (!currentPostId) throw new Error('currentPostId error')

        const commentReactionsColRef = collection(db, "version/v1/groups", currentGroupId, "posts", currentPostId, "comments", commentId, "reactions").withConverter(firestoreConverter(storedReactionSchema))
        const createdAtAscQuery = query(commentReactionsColRef, orderBy("createdAt", "asc"))


        return new Promise(async (resolve, reject) => {
            onSnapshot(createdAtAscQuery, async (snapshot) => {
                if (snapshot.empty) {
                    queryClient.setQueryData(["useCommentReactions", currentGroupId, currentPostId, commentId], [])
                    resolve([])
                }

                const reactions = await Promise.all(
                    snapshot.docs.map(
                        async (document) => {
                            if (!document.exists()) return { empty: true }
                            if (!document.data().members.length) return { empty: true }

                            return {
                                empty: false,
                                id: document.id,
                                reactionEmoji: document.data().reactionEmoji,
                                count: document.data().members.length,
                                doesExistCurrentUserIdInMembersIdList: document.data().members.map(item => item.id).includes(currentUser.uid)
                            }

                        }))

                const filteredValue = reactions
                    .filter((item): item is { empty: false, id: string, reactionEmoji: string, count: number, doesExistCurrentUserIdInMembersIdList: boolean } => item.empty === false)
                    .map((item) => {
                        const { empty, ...rest } = item
                        return rest
                    })

                queryClient.setQueryData(["useCommentReactions", currentGroupId, currentPostId, commentId], filteredValue)
                resolve(filteredValue)



            })



        })

    }

    return useQuery({ queryKey: ["useCommentReactions", currentGroupId, currentPostId, commentId], queryFn: getReactions, enabled: !!currentUser && !!db && !!currentGroupId })
}