import { ErrorMessage } from "@hookform/error-message";
import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonNote, IonPage, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { FormProvider, Controller, UseFormReturn, SubmitHandler } from "react-hook-form";
import { EmailInput, PasswordInput } from "../Home/ui";

import { arrowForwardOutline } from "ionicons/icons";

type Props = {
    rhfMethods: UseFormReturn<any>;
    onSubmitFunction: SubmitHandler<any>;
    isPending: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: Error | FirebaseError | undefined;

};

export function RegisterPresenter({
    rhfMethods,
    onSubmitFunction,
    isPending,
    isSuccess,
    isError,
    error,
}: Props) {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>

                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"></IonBackButton>
                    </IonButtons>
                    <IonTitle>Fridge</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Welcome to Fridge!</IonCardTitle>
                        <IonCardSubtitle>Create your account.</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <FormProvider {...rhfMethods}>
                            <form onSubmit={rhfMethods.handleSubmit(onSubmitFunction)}>
                                <EmailInput></EmailInput>
                                <PasswordInput
                                    initialPasswordVisible={true}
                                    hasHelperText={true}
                                    validateErrorMessage={true}
                                ></PasswordInput>
                                <IonItem>
                                    <Controller
                                        // control={rhfMethods.control}
                                        name="terms"
                                        render={({ field }) => (
                                            <IonCheckbox
                                                slot="start"
                                                {...field}
                                                onIonChange={({ detail: { checked } }) => {
                                                    field.onChange(checked);
                                                    rhfMethods.trigger();
                                                }}
                                                aria-label="I agree to the Term Of Use and Privacy Policy"
                                            // onBlur={onBlur}
                                            ></IonCheckbox>
                                        )}
                                    />
                                    <IonLabel className="ion-text-wrap">
                                        I agree to the <a href="./">Term Of Use</a> and{" "}
                                        <a href="./">Privacy Policy</a>
                                    </IonLabel>
                                </IonItem>
                                {rhfMethods.formState.errors.terms ? (
                                    <IonNote color="danger">
                                        <ErrorMessage
                                            errors={rhfMethods.formState.errors}
                                            name="terms"
                                        ></ErrorMessage>
                                    </IonNote>
                                ) : null}
                                <IonButton
                                    type="submit"
                                    expand="block"
                                    disabled={!rhfMethods.formState.isValid}
                                >
                                    {isPending ? (
                                        <IonSpinner></IonSpinner>
                                    ) : (
                                        "Send verification email"
                                    )}
                                    <IonIcon
                                        icon={arrowForwardOutline}
                                        slot="end"
                                        color="white"
                                    ></IonIcon>
                                </IonButton>
                                {isError && error instanceof FirebaseError ? (
                                    <IonNote color={"danger"}>{error.message}</IonNote>
                                ) : null}
                            </form>
                        </FormProvider>

                        <p
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            Already have an account?
                            <IonButton fill="clear" size="small" routerLink="/">
                                Sign in
                            </IonButton>
                        </p>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
}