import { useQuery } from "@tanstack/react-query";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { UseFirebaseApp } from "./UseFirebaseApp";

export function UseFirestoreInitialization() {
    const { data: app } = UseFirebaseApp()
    function getFirestoreInstance() {
        if (!app) throw new Error("app is not initialized")
        const db = getFirestore(app)
        if (import.meta.env.MODE === "development") {
            connectFirestoreEmulator(db, "localhost", 8080)
        }
        return db

    }
    return useQuery({ queryFn: getFirestoreInstance, queryKey: ["isFirestoreInitialized"], staleTime: Infinity, gcTime: Infinity, enabled: !!app })
}