import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { ToolBarContainer } from "./ui/toolBar/ToolBarContainer";
import { FeedContainer } from "./feature/feed/FeedContainer";
import { PostChatContainer } from "./feature/postChat/PostChatContainer";
import { useRef } from "react";
import { add, addCircleOutline } from "ionicons/icons";
import { PostContainer } from "./feature/post/PostContainer";
import { PostJournalContainer } from "./feature/postJournal/PostJournalContainer";

export function GroupFeedPresenter({
  chatModalRef,
  journalModalRef,
}: {
  chatModalRef: React.RefObject<HTMLIonModalElement>;
  journalModalRef: React.RefObject<HTMLIonModalElement>;
}) {
  const pageRef = useRef(null);
  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <ToolBarContainer />
      </IonHeader>
      <IonContent>
        <FeedContainer pageRef={pageRef} journalModalRef={journalModalRef} />
        <PostContainer
          // chatModalRef={chatModalRef}
          journalModalRef={journalModalRef}
        ></PostContainer>
      </IonContent>

      {/* <PostChatContainer chatModalRef={chatModalRef}></PostChatContainer> */}
      {/* <PostJournalContainer
      
      /> */}
    </IonPage>
  );
}
