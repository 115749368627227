import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";


import { z } from "zod";
import { firestoreConverter } from "../../../util/firestoreConverter";
import { fullyRegisteredUserSchema, groupNameFormSchema, groupWithIdSchema, unstoredGroupSchema } from "../../../../sharedEntities";
import { UseFirebaseAuth } from "../../../globalHook/UseFirebaseAuth";
import { UseFirebaseAuthUser } from "../../../globalHook/UseFirebaseAuthUser";
import { UseFirestoreInitialization } from "../../../globalHook/UseFirestoreInitialization";

export const UseSendGroupName = () => {
  const queryClient = useQueryClient();
  const { data: firebaseAuthUser } = UseFirebaseAuthUser()
  const { data: db } = UseFirestoreInitialization()

  const setGroupName = async (inputData: z.infer<typeof groupNameFormSchema>) => {
    if (!firebaseAuthUser) throw new Error("current user error");
    if (!db) throw new Error("firestore error");

    const userDocRef = doc(
      db,
      "/version/v1/users",
      firebaseAuthUser.uid
    ).withConverter(firestoreConverter(fullyRegisteredUserSchema));

    const userDocSnapShot = await getDoc(userDocRef);
    if (!userDocSnapShot.exists()) throw new Error("user doc does not exist");

    const groupCollectionRef = collection(
      db,
      "/version/v1/groups"
    ).withConverter(firestoreConverter(unstoredGroupSchema));

    const groupDocRef = await addDoc(groupCollectionRef, {
      name: inputData.name,
      member: [userDocRef],
      owner: userDocRef,
      pendingMember: [],
    });

    if (!groupDocRef.id) throw new Error("get group id error");

    const groupWithIdDocRef = groupDocRef.withConverter(firestoreConverter(groupWithIdSchema))

    await updateDoc(groupWithIdDocRef, { id: groupDocRef.id });
    await updateDoc(userDocRef, {
      groups: [groupDocRef],
    });

  };
  return useMutation({
    mutationFn: setGroupName,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userType"] });
    },
  });
};
