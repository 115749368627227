import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonNote, IonPage, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from "@ionic/react";
import topLogo from "./topLogo.jpeg";
import { EmailInput, PasswordInput } from "../Home/ui";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { FirebaseError } from "firebase/app";

type Props = {
    rhfMethods: UseFormReturn<any>;
    isPending: boolean;
    isError: boolean;
    error: Error | FirebaseError | undefined;
    onSubmit: (data: any) => void;
};
export function SignInPresenter({
    rhfMethods,
    onSubmit,
    isPending,
    isError,
    error
}: Props) {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Fridge</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Sign in</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <FormProvider {...rhfMethods}>
                                    <form onSubmit={rhfMethods.handleSubmit(onSubmit)} action="/">
                                        <EmailInput></EmailInput>
                                        <PasswordInput
                                            initialPasswordVisible={false}
                                            hasHelperText={false}
                                            validateErrorMessage={false}
                                        ></PasswordInput>
                                        <IonButton type="submit" expand="block" className="ion-margin-top">
                                            {isPending ? <IonSpinner></IonSpinner> : "Sign In"}
                                        </IonButton>
                                        {isError &&
                                            (error instanceof FirebaseError || error instanceof Error) ? (
                                            <IonRow>
                                                <IonNote color={"danger"}>{error.message}</IonNote>
                                            </IonRow>
                                        ) : null}
                                    </form>
                                </FormProvider>

                            </IonRow>

                            <IonRow className="ion-justify-content-center">
                                <IonButton
                                    fill="clear"
                                    size="small"
                                    routerLink="/forgotPassword"
                                >
                                    Forgot your password?
                                </IonButton>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonText color={"dark"}>or</IonText>
                            </IonRow>
                            <IonRow class="ion-justify-content-center">
                                <IonButton
                                    expand="block"
                                    color="dark"
                                    routerLink="/register"


                                >
                                    Create a new account
                                </IonButton>
                            </IonRow>




                        </IonGrid>

                    </IonCardContent>

                </IonCard>

            </IonContent>


        </IonPage>
    );
}