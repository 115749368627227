import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { ReactionsContainer } from "../GroupFeed/feature/feed/ui/reactions/ReactionsContainer";
import { PostDetailContainer } from "./ui/PostDetail/PostDetailContainer";
import { CommentListContainer } from "./feature/commentList/CommentListContainer";
import { PostCommentContainer } from "./feature/postComment/PostCommentContainer";

type Props = {
  backButtonUrlString: string;
};
export function ThreadPresenter({ backButtonUrlString }: Props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={backButtonUrlString}></IonBackButton>
          </IonButtons>
          <IonTitle>thread</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <PostDetailContainer></PostDetailContainer>
        <CommentListContainer></CommentListContainer>
        <PostCommentContainer></PostCommentContainer>
      </IonContent>
    </IonPage>
  );
}
