import { useMutation } from "@tanstack/react-query"
import { doc, getDoc, updateDoc, arrayRemove } from "firebase/firestore"

import { ReactionType, storedReactionSchema } from "../../../../../../../../sharedEntities/reactionType"
import { UseCurrentGroupId } from "../../../../../../../globalHook/useCurrentGroupId"

import { firestoreConverter } from "../../../../../../../util/firestoreConverter"
import { UseFirestoreInitialization } from "../../../../../../../globalHook/UseFirestoreInitialization"
import { UseCurrentPostId } from "../../../../../../../globalHook/useCurrentPostId"
import { UseUserInfo } from "../../../../../../../globalHook/UseUserInfo"
import { fullyRegisteredUserSchema } from "../../../../../../../../sharedEntities"



export function UseCommentReactionRemove() {
    const { data: currentUser } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: currentPostId } = UseCurrentPostId()


    async function removeReaction({ commentId, reactionId }: { commentId: string, reactionId: string }) {
        if (!currentUser) throw new Error('currentUser error')
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')
        if (!currentPostId) throw new Error('currentPostId error')

        const reactionDocRef = doc(db, "version/v1/groups", currentGroupId, "posts", currentPostId, "comments", commentId, "reactions", reactionId).withConverter(firestoreConverter(storedReactionSchema))
        const reactionDocsnapshot = await getDoc(reactionDocRef)
        if (!reactionDocsnapshot) throw new Error("this reaction doc does not exist")

        const currentUserDocRef = doc(db, "version/v1/users", currentUser.uid).withConverter(firestoreConverter(fullyRegisteredUserSchema))

        await updateDoc(reactionDocRef, { members: arrayRemove(currentUserDocRef) })
    }

    return useMutation({ mutationFn: removeReaction })

}