import { FirebaseError } from "firebase/app";
import { UseForgotPassword } from "./UseForgotPassword";
import { ForgotPasswordPresenter } from "./ForgotPasswordPresenter";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { forgotPasswordResendFormSchema } from "../../../../sharedEntities";

export function ForgotPasswordContainer() {
  type FormType = z.infer<typeof forgotPasswordResendFormSchema>

  const rhfMethods = useForm<FormType>({ resolver: zodResolver(forgotPasswordResendFormSchema) });
  const { mutate, isError, error, isSuccess, isPending } = UseForgotPassword();
  const onSubmitFunction: SubmitHandler<FormType> = (data, e) => {
    e?.preventDefault();
    mutate(rhfMethods.watch());
  };
  return (
    <ForgotPasswordPresenter
      rhfMethods={rhfMethods}
      isError={isError}
      isSuccess={isSuccess}
      isPending={isPending}
      error={
        error instanceof FirebaseError || error instanceof Error
          ? error
          : undefined
      }
      onSubmitFunction={onSubmitFunction}
    ></ForgotPasswordPresenter>
  );
}
