import { ErrorMessage } from "@hookform/error-message";
import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonNote, IonPage, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { Control, Controller, UseFormReturn } from "react-hook-form";

type Props = {
    onSubmit: () => void,
    control: Control<any>,
    name: string,
    errors: any,
    isError: boolean,
    error: Error | FirebaseError | undefined,
    buttonDisabled: boolean,

};
export function CreateGroupPresenter({
    onSubmit,
    control,
    name,
    errors,
    isError,
    error,
    buttonDisabled
}: Props) {

    return <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Fridge</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonText>
                        <h2>What is your group name?</h2>
                    </IonText>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonText>
                        <h6>You can change a group name anytime!</h6>
                    </IonText>
                </IonRow>
                <form onSubmit={onSubmit} id="setDisplayName">
                    <IonRow className="ion-justify-content-center">
                        <Controller
                            control={control}
                            name={name}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <IonItem>
                                    <h1>
                                        <IonInput
                                            alia-label="Display name"
                                            labelPlacement="floating"
                                            onIonInput={(e) => onChange(e.detail.value)}
                                        ></IonInput>
                                    </h1>
                                </IonItem>
                            )}
                        />
                    </IonRow>
                    <IonRow>
                        <ErrorMessage
                            errors={errors} name={name} />
                        {isError &&
                            (error instanceof FirebaseError || error instanceof Error) ? (
                            <IonRow>
                                <IonNote color={"danger"}>{error.message}</IonNote>
                            </IonRow>
                        ) : null}
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonButton expand="block" className="ion-margin" disabled={buttonDisabled} type="submit" id="setDisplayName">Continue</IonButton>
                        </IonCol>

                    </IonRow>
                </form>
            </IonGrid>

        </IonContent>
    </IonPage>
}