import { z } from "zod";
import { DocumentReference, FieldValue, Timestamp } from "firebase/firestore";

/** firestoreのFieldValueオブジェクトを受け付けるスキーマ */
export const firestoreFieldValueSchema = z.custom<FieldValue>(
    (data) => data instanceof FieldValue
);

/** firestoreのTimestampオブジェクトを受け付けるスキーマ（FieldValueは受け付けない） */
export const firestoreTimestampSchema = z.instanceof(Timestamp);

/** firestoreのTimestampオブジェクトまたはFieldValueオブジェクトを受け付けるスキーマ */
export const firestoreTimestampLooseSchema = z.union([
    firestoreFieldValueSchema,
    firestoreTimestampSchema,
]);

export function documentReferenceSchema<T>() {
    return z.custom<DocumentReference<T>>((data) => { return data instanceof DocumentReference })
}
