// import { FirestoreDataConverter, QueryDocumentSnapshot, WithFieldValue } from "@firebase/firestore";
import { FirestoreDataConverter, QueryDocumentSnapshot, Timestamp, WithFieldValue, serverTimestamp } from "firebase/firestore";
import { z } from "zod";

interface DocumentSnapshotType {
    [key: string]: any | Timestamp | Date;
}

export const firestoreConverter = <T extends z.ZodType<any, any>>(zodSchema: T): FirestoreDataConverter<z.infer<T>> => ({
    toFirestore: (data: WithFieldValue<T>) => {
        console.log("toFirestore", { data })
        console.log(zodSchema.safeParse(data))
        zodSchema.parse(data)

        if (Object.hasOwn(data, "createdAt")) {
            return { ...data, updatedAt: serverTimestamp() }
        } else {
            return { ...data, createdAt: serverTimestamp(), updatedAt: serverTimestamp() }
        }
        return data;


    },

    fromFirestore: (snapshot: QueryDocumentSnapshot<T>) => {

        const data = snapshot.data({ serverTimestamps: "estimate" });
        zodSchema.parse(data)
        return data;
    },
});