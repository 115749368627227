import { useLocation } from "react-router";
import { UseEmailVerification } from "../hook/useEmailVerification";
import { VerifyEmailPresenter } from "../presenter/VerifyEmailPresenter";
import { useEffect, useMemo } from "react";
import { FirebaseError } from "firebase/app";

export function VerifyEmailContainer() {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { mutate, isSuccess, isPending, isError, error } =
    UseEmailVerification(query);

  useEffect(() => {
    mutate();
  }, []);

  return (
    <VerifyEmailPresenter
      isSuccess={isSuccess}
      isPending={isPending}
      isError={isError}
      error={
        error instanceof FirebaseError || error instanceof Error
          ? error
          : undefined
      }
    />
  );
}
