import { useMutation } from "@tanstack/react-query";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export function UseForgotPassword() {
    async function forgotPassword(formData: { email: string }) {
        const auth = getAuth()
        if (!auth) throw new Error("auth error")

        return await sendPasswordResetEmail(auth, formData.email)
    }

    return useMutation({ mutationFn: forgotPassword })
}