import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { UseCurrentGroupId } from "../../../../../../../globalHook/useCurrentGroupId";
import { UseFirestoreInitialization } from "../../../../../../../globalHook/UseFirestoreInitialization";
import { firestoreConverter } from "../../../../../../../util/firestoreConverter";
import { storedReactionSchema } from "../../../../../../../../sharedEntities/reactionType";
import { fullyRegisteredUserSchema, } from "../../../../../../../../sharedEntities";
import { useMutation } from "@tanstack/react-query";
import { UseUserInfo } from "../../../../../../../globalHook/UseUserInfo";


export function UseReactionAdd() {
    const { data: userInfo } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()

    async function addReaction({ postId, reactionId }: { postId: string, reactionId: string }) {
        if (!userInfo) throw new Error('currentUser error')
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')

        const reactionDocRef = doc(db, "version/v1/groups", currentGroupId, "posts", postId, "reactions", reactionId).withConverter(firestoreConverter(storedReactionSchema))
        const reactionDocsnapshot = await getDoc(reactionDocRef)
        if (!reactionDocsnapshot) throw new Error("this reaction doc does not exist")

        const currentUserDocRef = doc(db, "version/v1/users", userInfo.uid).withConverter(firestoreConverter(fullyRegisteredUserSchema))
        console.log("adding")
        await updateDoc(reactionDocRef, { members: arrayUnion(currentUserDocRef) })
    }

    return useMutation({ mutationFn: addReaction })

}