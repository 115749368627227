import { useQuery } from "@tanstack/react-query";
import { NoGroupUserType, EmailVerifiedButDisplayNameUnsetUserType, UnverifiedUserType, FullyRegisteredUserType, noGroupUserSchema, emailVerifiedButDisplayNameUnsetUserSchema, isNoGroupUserType, isEmailVerifiedButDisplayNameUnsetUserType, isUnverifiedEmailUserType, isFullyRegisteredUserType, unverifiedEmailUserSchema, fullyRegisteredUserSchema } from "../../sharedEntities";
import { UseFirebaseAuthUser } from "./UseFirebaseAuthUser";
import { UseFirestoreInitialization } from "./UseFirestoreInitialization";
import { doc, getDoc } from "firebase/firestore";
import { firestoreConverter } from "../util/firestoreConverter";
import { z } from "zod";


export const userTypeSchema = z.union([
    unverifiedEmailUserSchema,
    emailVerifiedButDisplayNameUnsetUserSchema,
    noGroupUserSchema,
    fullyRegisteredUserSchema,
])
export type UserPattern = "unverifiedEmailUser" | "emailVerifiedButDisplayNameUnsetUser" | "noGroupUser" | "fullyRegisteredUser"
export type UserInfoType =
    (
        UnverifiedUserType & { userPattern: "unverifiedEmailUser", isRegistered: false }
        | EmailVerifiedButDisplayNameUnsetUserType & { userPattern: "emailVerifiedButDisplayNameUnsetUser", isRegistered: false }
        | NoGroupUserType & { userPattern: "noGroupUser", isRegistered: false }
    ) | (
        FullyRegisteredUserType & { userPattern: "fullyRegisteredUser", isRegistered: true }
    )


export function UseUserType<TData = UserInfoType, TResult = TData>(select?: ((data: UserInfoType) => TResult)) {
    const { data: currentUser } = UseFirebaseAuthUser()
    const { data: db } = UseFirestoreInitialization()

    const queryKey = ["userType"]

    const detectUserType = async (): Promise<UserInfoType> => {
        if (!currentUser) throw new Error("user is not logged in")
        if (!db) throw new Error("firestore is not initialized")
        const userDocumentRef = doc(db, `version/v1/users/${currentUser.uid}`).withConverter(firestoreConverter(userTypeSchema))
        const userDocumentSnapshot = await getDoc(userDocumentRef)
        const hasUserDocumentSnapshot = userDocumentSnapshot.exists()
        if (!hasUserDocumentSnapshot) throw new Error("user document does not exist")
        const userDocumentData = userDocumentSnapshot.data()
        const userInfo: unknown = { ...userDocumentData, email: currentUser.email, emailVerified: currentUser.emailVerified }


        if (isUnverifiedEmailUserType(userInfo)) {
            return {
                ...userInfo,
                userPattern: "unverifiedEmailUser",
                isRegistered: false
            }
        }

        if (isEmailVerifiedButDisplayNameUnsetUserType(userInfo)) {
            return {
                ...userInfo,
                userPattern: "emailVerifiedButDisplayNameUnsetUser",
                isRegistered: false
            }
        }

        if (isNoGroupUserType(userInfo)) {
            return {
                ...userInfo,
                userPattern: "noGroupUser",
                isRegistered: false
            }
        }

        if (isFullyRegisteredUserType(userInfo)) {
            return {
                ...userInfo,
                userPattern: "fullyRegisteredUser",
                isRegistered: true
            }
        }

        throw new Error("unknown user type")
    }

    return useQuery({ queryKey, enabled: !!currentUser, queryFn: detectUserType, select });
}