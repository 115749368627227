import { z } from "zod";
import { firestoreTimestampSchema } from "./firestoreSchema";

export const groupInvitationFormSchema = z.object({
    available: z.boolean(),
})
export type UnstoredGroupInvitationType = z.infer<typeof groupInvitationFormSchema>

export const storedGroupInvitationSchema = groupInvitationFormSchema.extend({
    createdAt: firestoreTimestampSchema,
    updatedAt: firestoreTimestampSchema
})

export type StoredGroupInvitationType = z.infer<typeof storedGroupInvitationSchema>


export type GroupInvitationType = UnstoredGroupInvitationType | StoredGroupInvitationType

