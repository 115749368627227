import { useQuery } from "@tanstack/react-query";
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { UseValidGroupId } from "../../hook/UseValidGroupId";
import { doc, getDoc } from "firebase/firestore";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { GroupType, storedGroupSchema } from "../../../../../../sharedEntities";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

export function UseUnapprovalMembersInfo() {
  const { data: db } = UseFirestoreInitialization();
  const { data: currentGroupId } = UseCurrentGroupId();

  async function getUnapprovalMembersInfo() {
    if (!db) throw new Error("db error");
    if (!currentGroupId) throw new Error("currentGroupId error");
    const groupDocRef = doc(
      db,
      "version/v1/groups",
      currentGroupId
    ).withConverter(firestoreConverter(storedGroupSchema));
    const groupDoc = await getDoc(groupDocRef);
    if (!groupDoc.exists()) throw new Error("group does not exist");
    const unapprovalMember = groupDoc.data().pendingMember;
    const hasUnapprovedMember = unapprovalMember && unapprovalMember.length > 0;
    const unapprovedMembersCount = unapprovalMember.length;
    return { hasUnapprovedMember, unapprovedMembersCount };
  }

  return useQuery({
    queryKey: ["unapprovalMembersInfo", currentGroupId],
    queryFn: getUnapprovalMembersInfo,
    enabled: !!db && !!currentGroupId,
  });
}
