import {
  IonRow,
  IonCol,
  IonLabel,
  IonChip,
  IonIcon,
  IonSkeletonText,
} from "@ionic/react";
import { AvatarWithSeed } from "../../../../../../../globalUi/avatar/AvatarWithSeed";
import { chatboxEllipsesOutline } from "ionicons/icons";

type Props = {
  commentInfo: {
    count: number;
    topThreeUsers: { uid: string }[];
  };
};
export function CommentButtonPresenter({ commentInfo }: Props) {
  return (
    <IonRow class="ion-align-items-center ion-justify-content-end">
      <IonCol size="auto">
        <IonChip>
          <IonIcon icon={chatboxEllipsesOutline} />
          <IonLabel>{commentInfo.count}</IonLabel>
          {commentInfo.topThreeUsers.map((user, index) => (
            <AvatarWithSeed
              uid={user.uid}
              size="12"
              key={index}
            ></AvatarWithSeed>
          ))}
        </IonChip>
      </IonCol>
    </IonRow>
  );
}

export function CommentButtonSkeltonPresenter() {
  return (
    <IonRow class="ion-align-items-center ion-justify-content-end">
      <IonCol size="auto">
        <IonChip>
          <IonIcon icon={chatboxEllipsesOutline} />
          <IonLabel>
            <IonSkeletonText></IonSkeletonText>
          </IonLabel>
        </IonChip>
      </IonCol>
    </IonRow>
  );
}
