import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonButton,
  IonCard,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  addCircleOutline,
  albumsOutline,
  happyOutline,
  homeOutline,
} from "ionicons/icons";
// import Tab1 from "./pages/Tab1";
// import Tab2 from "./pages/Tab2";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { Me } from "./pages/me/Me";
import { UseFirebaseAuthUser } from "./globalHook/UseFirebaseAuthUser";
import { EmailAction } from "./pages/emailAction/EmailAction";
import { AddUserToGroup } from "./pages/g/addUserToGroup/AddUserToGroup";
import { ManageMembers } from "./pages/g/manageMenbers/ManageMembers";
import { Feed } from "./pages/g/feed/Feed";
import { UseUserOnboardingState } from "./components/feature/SignIn/feature/userOnboarding/hooks/useUserOnboardingState";
import { Thread } from "./pages/g/thread/Thread";
import { AppUrlListener } from "./pages/AppUrlListener";
import "./App.css";
import { SignIn as SignIn } from "./pages/signIn/SignIn";
import { ForgotPassword } from "./pages/forgotPassword/ForgotPassword";
import { Register } from "./pages/register/Register";
import { UseUserType } from "./globalHook/UseUserType";
import { Welcome } from "./pages/welcome/Welcome";
import { Invite } from "./pages/invite/Invite";
import { Request } from "./pages/request/Request"
import { CreateGroup } from "./pages/createGroup/CreateGroup";

setupIonicReact();

const App: React.FC = () => {
  const { data: firebaseAuthUser, isSuccess, isLoading } = UseFirebaseAuthUser();
  const { data: userInfo, isLoading: isUserInfoLoading, isSuccess: isUserInfoSuccess } = UseUserType()
  console.log({ userInfo })



  if (isLoading) return <IonApp><IonSpinner></IonSpinner></IonApp>

  if (firebaseAuthUser === null) { //non-authenticated user
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <AppUrlListener></AppUrlListener>
            <Route path="/forgotPassword" exact component={ForgotPassword} />
            <Route path="/register" exact component={Register} />
            <Route path="/emailAction" exact render={(props) => (<EmailAction {...props} redirectPath="/signIn" />)}></Route>
            <Route path="/invite/:groupId/:invitationKey" exact render={(props) => <Invite {...props} redirectPath="/signIn" />}></Route>
            <Route path="/signIn" exact component={SignIn}></Route>
            <Route path="/request/:groupId" exact component={Request}></Route>
            <Redirect from="/welcome" exact to={"/signIn"} />
            <Redirect from="/" exact to={"/signIn"} />
            <Route render={() => <IonPage>
              <IonHeader>
                <IonToolbar>
                  <IonTitle>Page not found</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <IonCard>
                  <IonGrid>
                    <IonRow className="ion-justify-content-center">
                      <IonLabel>
                        Sorry. The page you are looking for does not exist.
                      </IonLabel>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonButton
                        routerLink="/signIn"
                        routerDirection="none"
                      >Back to home</IonButton>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              </IonContent>
            </IonPage>} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    )
  }
  if (isUserInfoLoading) return <IonApp><IonSpinner></IonSpinner></IonApp>

  if (isUserInfoSuccess && !userInfo.isRegistered) {
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/welcome" component={Welcome}></Route>
            <Route path="/invite/:groupId/:invitationKey" exact render={(props) => <Invite {...props} redirectPath="/welcome" />}></Route>
            <Route path="/request/:groupId" exact component={Request}></Route>
            <Route path="/createGroup" exact component={CreateGroup} ></Route>
            <Redirect to={"/welcome"} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    )
  }

  if (isUserInfoSuccess && userInfo.isRegistered)
    return (
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <AppUrlListener></AppUrlListener>
              <Route
                exact
                path="/g/:groupId"
                render={(props) =>
                  <Feed {...props}></Feed>
                }
              ></Route>
              <Route path="createGroup" exact component={CreateGroup} ></Route>
              <Route
                exact
                path="/g/:groupId/thread/:postId"
                component={Thread}
              ></Route>

              <Route
                exact
                path="/g/:groupId/addGroupMember"
                component={AddUserToGroup}
              ></Route>
              <Route
                exact
                path="/g/:groupId/manageMembers"
                component={ManageMembers}
              ></Route>

              <Route
                exact
                path="/me"
                component={Me}
              ></Route>
              <Route path="/emailAction" component={EmailAction}></Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom" translucent>
              <IonTabButton tab="me" href="/me">
                <IonIcon icon={happyOutline} />
              </IonTabButton>
              <IonTabButton tab="add" href="/add">
                <IonIcon icon={addCircleOutline} />
              </IonTabButton>
              <IonTabButton tab="home" href="/home">
                <IonIcon icon={homeOutline} />
              </IonTabButton>
              <IonTabButton tab="tab2" href="/timeline">
                <IonIcon icon={albumsOutline} />
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    );

  return <div>something wrong</div>
};
export default App;
