import { SubmitHandler, useForm } from "react-hook-form";
import { RegisterPresenter } from "./RegisterPresenter";
import { zodResolver } from "@hookform/resolvers/zod";

import { UseFirebaseAuthCreateUserWithEmailAndPassowrd } from "./useFirebaseAuthCreateUserWithEmailAndPassowrd";
import { FirebaseError } from "firebase/app";
import { z } from "zod";
import { signUpFormSchema } from "../../../../sharedEntities";

type Props = {};
export function RegisterContainer({ }: Props) {
    type FormType = z.infer<typeof signUpFormSchema>;

    const rhfMethods = useForm<FormType>({
        resolver: zodResolver(signUpFormSchema),
    });

    const { mutate, isError, isSuccess, error, isPending } =
        UseFirebaseAuthCreateUserWithEmailAndPassowrd();

    const onSubmitFunction: SubmitHandler<FormType> = (data, e) => {
        e?.preventDefault();

        mutate(data);
    };


    return <RegisterPresenter

        rhfMethods={rhfMethods}
        onSubmitFunction={onSubmitFunction}
        isError={isError}
        isSuccess={isSuccess}
        isPending={isPending}
        error={
            error instanceof FirebaseError || error instanceof Error
                ? error
                : undefined
        }

    />
}