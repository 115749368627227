import { useMutation } from "@tanstack/react-query"
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification } from "firebase/auth"
import { doc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore";
import { z } from "zod";
import { firestoreConverter } from "../../../util/firestoreConverter";
import { signUpFormSchema, unstoredSignUpUserSchema } from "../../../../sharedEntities";


export const UseFirebaseAuthCreateUserWithEmailAndPassowrd = () => {
    type FormType = z.infer<typeof signUpFormSchema>

    const createUser = async (formType: FormType) => {
        console.log({ formType })
        const auth = getAuth()
        const email = formType.email
        const password = formType.password
        const createUserResult = await createUserWithEmailAndPassword(auth, email, password)
        if (!createUserResult) throw new Error("create user error")
        if (!auth.currentUser) throw new Error("get current user error")
        const db = getFirestore()
        const userDocRef = doc(db, "version/v1/users", auth.currentUser.uid).withConverter(firestoreConverter(unstoredSignUpUserSchema))
        await setDoc(userDocRef, {
            terms: true,
            agreedAt: serverTimestamp(),
            avatarSeed: "",
            uid: auth.currentUser.uid,
        })

        return await sendEmailVerification(auth.currentUser)
    }


    return useMutation({ mutationFn: createUser })

}