import { CommentButtonPlaceholder } from "./CommentButtonPlaceholder";
import {
  CommentButtonPresenter,
  CommentButtonSkeltonPresenter,
} from "./CommentButtonPresenter";
import { UseRealtimeCommentInfo } from "./useRealtimeCommentInfo";
export function CommentButtonContainer({ postId }: { postId: string }) {
  const { data: commentInfo } = UseRealtimeCommentInfo(postId);

  if (!commentInfo) return <CommentButtonPlaceholder />;

  return <CommentButtonPresenter commentInfo={commentInfo} />;
}
