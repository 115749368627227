import { useEffect } from "react";
import { UseCurrentGroupId } from "../../../globalHook/useCurrentGroupId";
import { UseSetCurrentGroupId } from "../../../globalHook/useSetCurrentGroupId";
import { UseCurrentPostId } from "../../../globalHook/useCurrentPostId";
import { UseSetCurrentPostId } from "../../../globalHook/UseSetCurrentPostId";
import { UseIsTabBarShow } from "../../../globalHook/useIsTabBarShow";
import { ThreadPresenter } from "./ThreadPresenter";

export function ThreadContainer() {
  const { data: currentGroupId, isLoading: currentGroupIdIsLoading } =
    UseCurrentGroupId();
  const { data: currentPostId, isLoading: currentPostIdIsLoading } =
    UseCurrentPostId();

  const backButtonUrlString = `/g/${currentGroupId}`;

  if (!currentGroupId || !currentPostId) return null;
  if (currentGroupIdIsLoading || currentPostIdIsLoading) return null;
  return <ThreadPresenter backButtonUrlString={backButtonUrlString} />;
}
