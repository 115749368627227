import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { PostSelectorContainer } from "./PostSelector/PostSelectorContainer";

type Props = {
  modalRef: React.RefObject<HTMLIonModalElement>;
};
export function PostModalPresenter({ modalRef }: Props) {
  return (
    <IonModal ref={modalRef}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/home"></IonBackButton>
            </IonButtons>
            <IonTitle>Post</IonTitle>
          </IonToolbar>
        </IonHeader>
        <PostSelectorContainer></PostSelectorContainer>
      </IonPage>
    </IonModal>
  );
}
