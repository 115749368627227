import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonLabel, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { CardWithoutForm } from "../../../Home/ui/cardWithoutForm.tsx/CardWithoutForm";
import { mailOpen, reloadOutline, sendOutline } from "ionicons/icons";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  onResendButtonClick: () => void,
  onRefreshButtonClick: () => void,
}

export const VerifyEmailPresenter = ({
  onResendButtonClick,
  onRefreshButtonClick,
}: Props) => {
  return (


    <IonContent>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonText>
            <h1>Please check your inbox</h1>
          </IonText>

        </IonRow>
        <IonRow className="ion-justify-content-center">

          <IonText>
            <h6>
              We sent a verification email to your email address.
            </h6>
          </IonText>

        </IonRow>
      </IonGrid>
      <div style={{ bottom: "50%", position: "absolute", right: 0, left: 0 }}>
        <IonButton expand="block" className="ion-margin" onClick={onResendButtonClick}>
          <IonIcon icon={sendOutline} className={"ion-margin"}></IonIcon>
          Resend Verification Email
        </IonButton>
        <IonButton expand="block" className="ion-margin" color={"dark"} onClick={onRefreshButtonClick}>
          <IonIcon icon={reloadOutline} className="ion-margin"></IonIcon>
          Reload Page
        </IonButton>
      </div>
    </IonContent>


  );
};
