import { RouteComponentProps } from "react-router";
import { ThreadContainer } from "../../../components/feature/Thread/ThreadContainer";
import { UseSetCurrentGroupId } from "../../../globalHook/useSetCurrentGroupId";
import { UseSetCurrentPostId } from "../../../globalHook/UseSetCurrentPostId";
import { UseFirestoreInitialization } from "../../../globalHook/UseFirestoreInitialization";
import { useEffect } from "react";
import { UseUserInfo } from "../../../globalHook/UseUserInfo";

interface ThreadPageProps
  extends RouteComponentProps<{
    groupId: string;
    postId: string;
  }> { }

export function Thread({ match }: ThreadPageProps) {
  const { data: currentUser } = UseUserInfo();
  const { data: db } = UseFirestoreInitialization();
  const { mutate: setCurrentGroupId, isSuccess: setCurrentGroupIdIsSuccess } =
    UseSetCurrentGroupId();
  const { mutate: setCurrentPostId, isSuccess: setCurrentPostIdIsSuccess } =
    UseSetCurrentPostId();

  useEffect(() => {
    setCurrentGroupId(match.params.groupId);
    setCurrentPostId(match.params.postId);
  }, [match.params.groupId, match.params.postId]);

  return <ThreadContainer></ThreadContainer>;
}
