import { SubmitHandler, useForm } from "react-hook-form";
import { SignInPresenter } from "./SignInPresenter";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseFirebaseAuthSignIn } from "./useFirebaseAuthSignIn";
import { FirebaseError } from "firebase/app";
import { z } from "zod";
import { signInFormSchema } from "../../../../sharedEntities";

type Props = {};

export function SignInContainer({ }: Props) {
  type FormType = z.infer<typeof signInFormSchema>;
  const zodSchema = signInFormSchema;

  const rhfMethods = useForm<FormType>({
    resolver: zodResolver(zodSchema),
  });
  const { mutate, isError, error, isSuccess, isPending } =
    UseFirebaseAuthSignIn();

  const onSubmit: SubmitHandler<FormType> = (data, e) => {
    e?.preventDefault();
    mutate(data)
  }


  return <SignInPresenter
    rhfMethods={rhfMethods}
    onSubmit={onSubmit}
    isPending={isPending}
    isError={isError}
    error={error instanceof FirebaseError || error instanceof Error
      ? error
      : undefined}
  ></SignInPresenter>;
}