import { useForm } from "react-hook-form";
import { VerifyEmailPresenter } from "./VerifyEmailPresenter";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseResendVerificationEmail } from "./useEmailVerification";
import { FirebaseError } from "firebase/app";
import { useQueryClient } from "@tanstack/react-query";
import { UseFirebaseAuthUser } from "../../../../../globalHook/UseFirebaseAuthUser";
import { z } from "zod";
import React from "react";

export const VerifyEmailContainer = () => {
  const queryClient = useQueryClient();

  const { mutate, isError, isSuccess, error } =
    UseResendVerificationEmail();
  const { data: userInfo } = UseFirebaseAuthUser();

  const onResendButtonClick = () => {
    mutate();
  };

  const onRefreshButtonClick = () => {
    userInfo?.reload();
    queryClient.invalidateQueries({ queryKey: ["userType"] });
  };
  return (
    <VerifyEmailPresenter
      onRefreshButtonClick={onRefreshButtonClick}
      onResendButtonClick={onResendButtonClick}
    />
  );
};
