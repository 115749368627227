import { useQuery } from "@tanstack/react-query";
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { UseValidGroupId } from "../../hook/UseValidGroupId";
import { doc, getDoc } from "firebase/firestore";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { GroupType, storedGroupSchema } from "../../../../../../sharedEntities";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";
import { UseUserInfo } from "../../../../../globalHook/UseUserInfo";

export function UseCurrentGroupName() {
  const { data: userInfo } = UseUserInfo();
  const { data: db } = UseFirestoreInitialization();
  const { data: currentGroupId } = UseCurrentGroupId();

  async function getCurrentGroupName() {
    if (!userInfo) throw new Error("currentUserError");
    if (!db) throw new Error("db error");
    if (!currentGroupId) throw new Error("group id error");

    const groupDocRef = doc(
      db,
      "version/v1/groups",
      currentGroupId
    ).withConverter(firestoreConverter(storedGroupSchema));
    const groupDoc = await getDoc(groupDocRef);
    if (!groupDoc.exists()) throw new Error("group does not exist");
    const name = groupDoc.data().name;
    if (!name) throw new Error("name error");
    return name;
  }

  return useQuery({
    queryKey: ["UseCurrentGroupName"],
    queryFn: getCurrentGroupName,
    enabled: !!userInfo && !!db && !!currentGroupId,
  });
}
