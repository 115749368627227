import { doc, getDoc } from "firebase/firestore";
import { UseFirestoreInitialization } from "../../../globalHook/UseFirestoreInitialization";
import { firestoreConverter } from "../../../util/firestoreConverter";

import { useQuery } from "@tanstack/react-query";
import { fullyRegisteredUserSchema } from "../../../../sharedEntities";
import { UseUserInfo } from "../../../globalHook/UseUserInfo";

export function UseMe() {
  const { data: userInfo } = UseUserInfo();
  const { data: firestoreInstance } = UseFirestoreInitialization();

  async function getMeInfo() {
    if (!userInfo) throw new Error("user info error");
    if (!firestoreInstance) throw new Error("firestore error");
    const userDocRef = doc(
      firestoreInstance,
      "version/v1/users",
      userInfo.uid
    ).withConverter(firestoreConverter(fullyRegisteredUserSchema));
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) throw new Error("user doc does not exist");
    return userDoc.data();
  }

  return useQuery({
    queryKey: ["me", userInfo?.uid],
    enabled: !!userInfo && !!firestoreInstance,
    queryFn: getMeInfo,
  });
}
