import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { clipboardOutline, shareOutline } from "ionicons/icons";
import { useRef } from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";

type Props = {
  onClickFunction: React.MouseEventHandler;
  currentGroupName: string;
  groupInvitationLinkUrl: string | null;
  createLinkIsSuccess: boolean;
};
export function AddGroupMemberPresenter({
  onClickFunction,
  currentGroupName,
  groupInvitationLinkUrl,
  createLinkIsSuccess,
}: Props) {
  const textareaRef = useRef<HTMLIonTextareaElement>(null);
  const handleFocus = async () => {
    const nativeTextareaElement = await textareaRef.current?.getInputElement();
    nativeTextareaElement?.select();
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Add new user to the group.</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow class="ion-justify-content-center ion-padding-vertical">
            <IonText>Add new user to {currentGroupName}</IonText>
          </IonRow>
          <IonRow class="ion-justify-content-center">
            {groupInvitationLinkUrl ? null : (
              <IonButton onClick={onClickFunction}>
                Create an invitation link
              </IonButton>
            )}
          </IonRow>
          <IonRow class="ion-justify-content-center">
            <IonItem>
              <IonTextarea
                label={"Share this link"}
                labelPlacement="floating"
                value={groupInvitationLinkUrl}
                autoGrow
                onFocus={handleFocus}
                ref={textareaRef}
              ></IonTextarea>
            </IonItem>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
