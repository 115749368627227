import { useMutation, useQueryClient } from "@tanstack/react-query";

export function UseSetCurrentPostId() {
    const queryClient = useQueryClient()

    // const { data: currentUser } = UseCurrentUser()
    // const { data: db } = UseFirestoreInitialization()
    // const { data: currentGroupId } = UseCurrentGroupId()

    async function setCurrentPostId(targetPostId: string) {

        // if (!currentUser) throw new Error('currentUser error')
        // if (!db) throw new Error('db error')
        // if (!currentGroupId) throw new Error("current groupId error")

        // const currentUserDocRef = doc(db, "version/v1/users", currentUser.uid).withConverter(firestoreConverter<UserType>())
        // const currentUserDocSnapshot = await getDoc(currentUserDocRef)
        // if (!currentUserDocSnapshot.exists()) throw new Error("current user doc doesnt exist")

        // const groups = currentUserDocSnapshot.data().groups
        // const groupsId = groups.map((item) => item.id)
        // const doesExistTargetGroupInUsersGroupsIdList = groupsId.includes(currentGroupId)

        // if (!doesExistTargetGroupInUsersGroupsIdList) throw new Error("this group id does not exist in current user groups list")

        // const targetGroupDocRef = doc(db, "version/v1/groups", currentGroupId).withConverter(firestoreConverter<GroupType>())
        // const targetGroupDocSnapshot = await getDoc(targetGroupDocRef)

        // if (!targetGroupDocSnapshot.exists()) throw new Error("this group does not exist")

        // const targetPostDocRef = doc(db, "version/v1/groups", currentGroupId, "posts", targetPostId).withConverter(firestoreConverter<PostType>())
        // const targetPostDosSnapshot = await getDoc(targetPostDocRef)

        // if (!targetPostDosSnapshot.exists()) throw new Error("this post does not exist")

        queryClient.setQueryData(["useCurrentPostId"], targetPostId)
    }

    return useMutation({ mutationFn: setCurrentPostId })
}
