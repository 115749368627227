import {
  IonButton,
  IonGrid,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { Redirect } from "react-router";

type Props = {
  isSuccess: boolean;
  isPending: boolean;
  isError: boolean;
  error: Error | FirebaseError | undefined;
};

export function VerifyEmailPresenter({
  isSuccess,
  isPending,
  isError,
  error,
}: Props) {
  return (
    <IonPage>
      <IonItem>
        {isPending ? (
          <IonGrid>
            <IonRow class="ion-justify-content-center">
              <IonSpinner name="dots" />
            </IonRow>
          </IonGrid>
        ) : null}
        {isSuccess ? <Redirect to="/"></Redirect> : null}
        {isError && error ? <IonLabel>{error.message}</IonLabel> : null}
      </IonItem>
    </IonPage>
  );
}
