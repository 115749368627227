import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuth, sendEmailVerification } from "firebase/auth";

export const UseResendVerificationEmail = () => {
    const queryClient = useQueryClient();
    const verifyEmail = async () => {
        const auth = getAuth();
        console.log(auth.currentUser)
        if (!auth.currentUser) throw new Error("current user is not set")
        return await sendEmailVerification(auth.currentUser)
    }
    return useMutation({
        mutationFn: verifyEmail, onSuccess(data, variables, context) {
            queryClient.invalidateQueries({ queryKey: ["userType"] })
        },
    })
}