import { IonPage } from "@ionic/react";
import { CardWithForm } from "../../../Home/ui/cardWithForm.tsx/CardWithForm";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { FirebaseError } from "firebase/app";

type Props = {
  rhfMethods: UseFormReturn<any>;
  onSubmitFunction: SubmitHandler<any>;
  isSuccess: boolean;
  isError: boolean;
  isPending: boolean;
  error: Error | FirebaseError | undefined;
};

export function ResetPasswordPresenter({
  rhfMethods,
  onSubmitFunction,
  isSuccess,
  isError,
  isPending,
  error,
}: Props) {
  return (
    <IonPage>
      <CardWithForm
        title="enter your new Password"
        subtitle="new password"
        content={"test"}
        rhfMethods={rhfMethods}
        onSubmitFunction={onSubmitFunction}
        isSuccess={isSuccess}
        isPending={isPending}
        isError={isError}
        error={error}
        inputs={[
          {
            inputLabel: "password",
            inputProps: { type: "password", autocomplete: "new-password" },
            inputRegisterName: "password",
          },
        ]}
        buttonLabel="OK"
        buttonProps={{}}
      ></CardWithForm>
    </IonPage>
  );
}
