import { RouteComponentProps } from "react-router";
import { CreateGroupContainer } from "../../components/feature/CreateGroup/CreateGroupContainer";

interface CreateGroupPageProps
    extends RouteComponentProps<{


    }> { }

export function CreateGroup({ }: CreateGroupPageProps) {
    return <CreateGroupContainer></CreateGroupContainer>;
}
