import { useQuery } from "@tanstack/react-query";

import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { firestoreConverter } from "../util/firestoreConverter";

import { FirebaseError } from "firebase/app";
import { UseFirestoreInitialization } from "./UseFirestoreInitialization";
import { UseUserInfo } from "./UseUserInfo";
import { fullyRegisteredUserSchema } from "../../sharedEntities";

export function UseAvatarSeedString(uid: string) {

    const { data: db } = UseFirestoreInitialization()
    const { data: currentUser } = UseUserInfo()
    async function getAvatarSeedString() {
        if (!currentUser) throw new Error("currentUser error")
        if (!db) throw new Error('db error')

        const userDocRef = doc(db, "version/v1/users", uid).withConverter(firestoreConverter(fullyRegisteredUserSchema))

        function isDummyUid(uid: string) {
            return uid === "......"
        }
        if (isDummyUid(uid)) return "fridgeDummy"

        try {
            const userDoc = await getDoc(userDocRef)

            if (!userDoc.exists()) throw new Error("user doc doesnt exist")

            const avatarSeed = userDoc.data().avatarSeed

            const avatarSeedValue = (avatarSeed) ? `${uid}-${avatarSeed}` : `${uid}`

            return avatarSeedValue
        } catch (error) {
            if (error instanceof Error || error instanceof FirebaseError) {
                throw new Error(error.message)
            } else {
                throw new Error("unknown error")
            }
        }

    }
    return useQuery({
        queryKey: ["avatar", uid],
        enabled: !!uid && !!currentUser && !!db,
        queryFn: getAvatarSeedString
    })
}