import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonModal,
  IonRow,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { Controller, SubmitHandler, UseFormReturn } from "react-hook-form";
import { FormType } from "./type";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  rhfMethods: UseFormReturn<FormType>;
  onSubmit: SubmitHandler<FormType>;
  modalRef: React.RefObject<HTMLIonModalElement>;
};
export function PostCommentPresenter({
  rhfMethods,
  onSubmit,
  modalRef,
}: Props) {
  return (
    <IonModal
      ref={modalRef}
      isOpen
      initialBreakpoint={0.1}
      breakpoints={[0.1, 0.6]}
      backdropDismiss={false}
      backdropBreakpoint={1}
    >
      <IonGrid fixed>
        <form onSubmit={rhfMethods.handleSubmit(onSubmit)}>
          <IonRow>
            <IonCol>
              <Controller
                control={rhfMethods.control}
                name={"message"}
                render={({ field }) => (
                  <IonTextarea
                    class="custom"
                    color={"dark"}
                    aria-labelledby="message"
                    alia-label="message"
                    placeholder="message"
                    autoGrow={true}
                    onIonInput={(e) => field.onChange(e)}
                    {...field}
                  ></IonTextarea>
                )}
              />
            </IonCol>
            <IonCol size="auto">
              <IonButton type="submit">submit</IonButton>
            </IonCol>
          </IonRow>
          <IonRow></IonRow>
        </form>
      </IonGrid>

      <ErrorMessage
        errors={rhfMethods.formState.errors}
        name={"message"}
        render={({ message }) => (
          <IonItem color={"light"}>
            <IonText>
              <small>{message}</small>
            </IonText>
          </IonItem>
        )}
      />
    </IonModal>
  );
}
