import { useQuery, useQueryClient } from "@tanstack/react-query"
import { onAuthStateChanged, Unsubscribe, User } from "firebase/auth"

import { UseFirebaseApp } from "./UseFirebaseApp";
import { UseFirebaseAuth } from "./UseFirebaseAuth";

export const UseFirebaseAuthUser = () => {
    const queryClient = useQueryClient()
    const { data: app } = UseFirebaseApp()
    const { data: auth } = UseFirebaseAuth()
    const queryKey = ["UseFirebaseAuthUser"]


    const getAuthState = async (): Promise<{
        currentUser: User | null
        unsubscribe: Unsubscribe | undefined
    }> => {
        if (!app) throw new Error("app is not initialized")
        if (!auth) throw new Error("auth is not initialized")
        let firstRun = true;

        const data = new Promise<{
            currentUser: User | null
            unsubscribe: Unsubscribe | undefined
        }>((resolve, reject) => {

            const unsubscribe = onAuthStateChanged(auth, (user) => {

                if (firstRun) {
                    firstRun = false;
                    resolve({ currentUser: user, unsubscribe })
                } else {
                    queryClient.setQueryData<{
                        currentUser: User | null,
                        unsubscribe: Unsubscribe | undefined
                    }>(queryKey, { currentUser: user, unsubscribe })
                }


            }, (error) => {
                if (firstRun) {
                    reject(error);
                    firstRun = false;
                } else {
                    queryClient.invalidateQueries({ queryKey });
                }
            }

            )



        }
        )

        const previousData = queryClient.getQueryData<{
            currentUser: User | null,
            unsubscribe: Unsubscribe | undefined
        }>(queryKey)
        previousData?.unsubscribe && previousData.unsubscribe();
        return data
    }

    return useQuery({
        queryKey: queryKey, queryFn: getAuthState, enabled: !!app && !!auth, select(data) {
            return data?.currentUser
        },
    })
}