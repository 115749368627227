import { SubmitHandler, useForm } from "react-hook-form";
import { ManageMembersPresenter } from "./ManageMembersPresenter";
import { UseGroupMembersInfo } from "./UseGroupMembersInfo";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseMemberApproval } from "./UseMemberApproval";
import { UseValidGroupId } from "../../hook/UseValidGroupId";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";
import { IonSpinner } from "@ionic/react";
import { RequestApprovalFormType, requestApprovalFormSchema } from "../../../../../../sharedEntities";

export function ManageMembersContainer() {
  const { data: currentGroupId } = UseCurrentGroupId();

  const rhfMethods = useForm<RequestApprovalFormType>({ resolver: zodResolver(requestApprovalFormSchema) });
  const { mutate, isSuccess: approveIsSuccess } = UseMemberApproval();

  const { data: membersInfo, isSuccess } = UseGroupMembersInfo();
  const onSubmitFunction: SubmitHandler<RequestApprovalFormType> = (data, e) => {
    e?.preventDefault();
    mutate(rhfMethods.watch());
  };
  const addGroupMemberUrlString = `/g/${currentGroupId}/addGroupMember`;
  if (!currentGroupId) return <IonSpinner></IonSpinner>;

  return (
    <ManageMembersPresenter
      rhfMethods={rhfMethods}
      onSubmitFunction={onSubmitFunction}
      membersInfo={isSuccess ? membersInfo : []}
      addGroupMemberUrlString={addGroupMemberUrlString}
    />
  );
}
