import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { UseFirestoreInitialization } from "../../../../../../../globalHook/UseFirestoreInitialization";
import { ReactionsType } from "./type";
import { firestoreConverter } from "../../../../../../../util/firestoreConverter";
import { storedReactionSchema } from "../../../../../../../../sharedEntities/reactionType";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseCurrentGroupId } from "../../../../../../../globalHook/useCurrentGroupId";
import { Unsubscribe } from "firebase/auth";
import { UseUserInfo } from "../../../../../../../globalHook/UseUserInfo";


export function UseRealtimeReactions({ postId }: { postId: string }) {
    const queryClient = useQueryClient()
    const { data: userInfo } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const queryKey = ["useReactions", currentGroupId, postId]


    async function getReactions(): Promise<{
        reactions: ReactionsType,
        unsubscribe: Unsubscribe
    }> {
        if (!userInfo) throw new Error("currentUser error")
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')

        let firstRun = true;


        const postDocRef = collection(db, "version/v1/groups", currentGroupId, "posts", postId, "reactions").withConverter(firestoreConverter(storedReactionSchema))
        const createdAtAscQuery = query(postDocRef, orderBy("createdAt", "asc"))


        const data = new Promise<{
            reactions: ReactionsType,
            unsubscribe: Unsubscribe
        }>(async (resolve, reject) => {
            const unsubscribe = onSnapshot(createdAtAscQuery,
                async (snapshot) => {
                    const reactions = await Promise.all(
                        snapshot.docs.map(
                            async (document) => {
                                if (!document.exists()) return { empty: true }
                                if (!document.data().members.length) return { empty: true }

                                return {
                                    empty: false,
                                    id: document.id,
                                    reactionEmoji: document.data().reactionEmoji,
                                    count: document.data().members.length,
                                    doesExistCurrentUserIdInMembersIdList: document.data().members.map(item => item.id).includes(userInfo.uid)
                                }

                            }))

                    const filteredValue = reactions
                        .filter((item): item is { empty: false, id: string, reactionEmoji: string, count: number, doesExistCurrentUserIdInMembersIdList: boolean } => item.empty === false)
                        .map((item) => {
                            const { empty, ...rest } = item
                            return rest
                        })

                    if (firstRun) {
                        resolve({
                            reactions: filteredValue,
                            unsubscribe: unsubscribe
                        })
                        firstRun = false
                    } else {
                        queryClient.setQueryData(queryKey, {
                            reactions: filteredValue,
                            unsubscribe: unsubscribe
                        })
                    }



                }, (error) => {
                    if (firstRun) {
                        reject(error)
                    } else {
                        queryClient.invalidateQueries({ queryKey })
                    }
                })



        })

        const previousData = queryClient.getQueryData<{
            reactions: ReactionsType,
            unsubscribe: Unsubscribe | undefined
        }>(queryKey)

        previousData?.unsubscribe && previousData.unsubscribe()

        return data

    }

    return useQuery({
        queryKey: queryKey,
        queryFn: getReactions,
        enabled: !!userInfo && !!db && !!currentGroupId,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: Infinity
    })
}