import { FeedPresenter } from "./FeedPresenter";
import {
  useRef,
} from "react";
import { UseRealtimeFeed } from "./useRealtimeFeed";

import { useMutation, useQueryClient } from "@tanstack/react-query";

type Props = {
  pageRef: React.RefObject<any>;
  journalModalRef: React.RefObject<any>;
};

export function FeedContainer({ pageRef, journalModalRef }: Props) {
  const queryClient = useQueryClient();
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const {
    data: realtimeFeedData,
    refetch,
    isLoading,
    isSuccess,
  } = UseRealtimeFeed();

  const { mutateAsync: refetching } = useMutation({
    mutationFn: async () => {
      const test = await refetch();
      if (test) return test;
      throw new Error("error");
    },
  });

  const onStartReached = async () => {
    console.log("onStartReached");
    if (realtimeFeedData?.hasMore) {
      return refetching(undefined, {
        onSuccess: async (data) => { },
      });
    }
  };

  if (isSuccess) {
    return (
      <FeedPresenter
        timelineItems={realtimeFeedData.items}
        hasMore={realtimeFeedData.hasMore}
        onStartReached={onStartReached}
        ionContentRef={ionContentRef}
        pageRef={pageRef}
        journalModalRef={journalModalRef}
      ></FeedPresenter>
    );
  }
  return <></>;
}
