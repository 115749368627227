import { BaseSyntheticEvent } from "react";
import {
  FormState,
  FieldError,
  ErrorOption,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
  FieldArrayPath,
  FieldArray,
  FieldErrors,
  RegisterOptions,
  UseFormRegisterReturn,
  useForm,
} from "react-hook-form";
import { SetDisplayNamePresenter } from "./SetDisplayNamePresenter";
import { FirebaseError } from "firebase/app";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseSetDisplayName } from "./useSetDisplayName";
import { z } from "zod";
import { displayNameFormSchema } from "../../../../../../sharedEntities";

export const SetDisplayNameContainer = () => {
  type FormType = z.infer<typeof displayNameFormSchema>
  const rhfMethods = useForm<FormType>({ resolver: zodResolver(displayNameFormSchema) });
  const { mutate, isSuccess, isError, isPending, error } =
    UseSetDisplayName();
  const onSubmit: SubmitHandler<FormType> = (data, e) => {
    console.log({ data })
    e?.preventDefault;
    mutate(data);
  };

  return (
    <SetDisplayNamePresenter
      rhfMethods={rhfMethods}
      control={rhfMethods.control}
      name="displayName"
      errors={rhfMethods.formState.errors}
      onSubmit={onSubmit}
      isSuccess={isSuccess}
      isError={isError}
      isPending={isPending}
      error={
        error instanceof FirebaseError || error instanceof Error
          ? error
          : undefined
      }
    ></SetDisplayNamePresenter>
  );
};
