import { UseSignOut } from "../../../globalHook/useSignOut";
import { UseMe } from "./useMe";
import { MePresenter } from "./MePresenter";

export function MeContainer() {
  const { data, isSuccess } = UseMe();
  const { mutate } = UseSignOut();
  function onClickFunction() {
    mutate();
  }
  return (
    <MePresenter
      uid={isSuccess ? data.uid : ""}
      displayName={isSuccess ? data.displayName : ""}
      onClickFunction={onClickFunction}
    ></MePresenter>
  );
}
