import { DocumentReference } from "firebase/firestore"
import { GroupWithIdType } from "./groupType"
import { z } from "zod"
import { documentReferenceSchema, firestoreTimestampLooseSchema, firestoreTimestampSchema } from "./firestoreSchema";


const emailSchema = z.string().email("please check your email address format.")
const passwordSchema = z.string().min(8, "password must contain at least 8 characters.")
const passwordSchemaWithoutError = z.string().min(1)

export const signUpFormSchema = z.object({
    email: emailSchema,
    password: passwordSchema,
    terms: z.literal(true, {
        errorMap: () => ({ message: "Please confirm terms" })
    }
    ),
})

export const unstoredSignUpUserSchema = z.object({
    terms: z.literal(true),
    avatarSeed: z.string(),
    uid: z.string().min(1),
    agreedAt: firestoreTimestampLooseSchema,
})

export const unverifiedEmailUserSchema = unstoredSignUpUserSchema.extend({
    terms: z.literal(true),
    agreedAt: firestoreTimestampSchema,
    createdAt: firestoreTimestampSchema,
    updatedAt: firestoreTimestampSchema,
})

export const forgotPasswordResendFormSchema = z.object({
    email: emailSchema,
})

export const confirmPasswordResetFormSchema = z.object({
    actionCode: z.string().min(1),
    password: passwordSchema,
})

export const signInFormSchema = z.object({
    email: emailSchema,
    password: passwordSchemaWithoutError,
})

export const emailVerifiedFormSchema = z.object({
    actionCode: z.string().min(1)
})

export type UnverifiedUserType = z.infer<typeof unverifiedEmailUserSchema>

export function isUnverifiedEmailUserType(userInfo: any): userInfo is UnverifiedUserType & { email: string, emailVerified: false } {
    return !!userInfo && "email" in userInfo && "emailVerified" in userInfo && userInfo.emailVerified === false
}

export const emailVerifiedButDisplayNameUnsetUserSchema = unverifiedEmailUserSchema

export type EmailVerifiedButDisplayNameUnsetUserType = z.infer<typeof emailVerifiedButDisplayNameUnsetUserSchema>

export function isEmailVerifiedButDisplayNameUnsetUserType(userInfo: any): userInfo is EmailVerifiedButDisplayNameUnsetUserType & { email: string, emailVerified: true } {
    return !!userInfo && "email" in userInfo && "emailVerified" in userInfo && userInfo.emailVerified === true && !("displayName" in userInfo)
}

export const displayNameFormSchema = z.object({
    displayName: z.string().min(1, "Display name is required")
})

export const noGroupUserSchema = emailVerifiedButDisplayNameUnsetUserSchema.extend({
    displayName: z.string()
});

export type NoGroupUserType = z.infer<typeof noGroupUserSchema>

export function isNoGroupUserType(userInfo: any): userInfo is NoGroupUserType & { email: string, emailVerified: true } {
    return "displayName" in userInfo && userInfo.emailVerified === true && !("groups" in userInfo)
}

export type FullyRegisteredUserType = z.infer<typeof noGroupUserSchema> & { groups: DocumentReference<GroupWithIdType>[] }

export const fullyRegisteredUserSchema: z.ZodType<FullyRegisteredUserType> = noGroupUserSchema.extend({
    groups: z.lazy(() => documentReferenceSchema<GroupWithIdType>().array())
})

export function isFullyRegisteredUserType(userInfo: any): userInfo is FullyRegisteredUserType & { email: string, emailVerified: true } {
    return "displayName" in userInfo && "groups" in userInfo && userInfo.emailVerified === true
}

export const looseUserTypeSchema = z.union([
    unverifiedEmailUserSchema,
    emailVerifiedButDisplayNameUnsetUserSchema,
    noGroupUserSchema,
    fullyRegisteredUserSchema,
])

export type LooseUserType = UnverifiedUserType | EmailVerifiedButDisplayNameUnsetUserType | NoGroupUserType | FullyRegisteredUserType