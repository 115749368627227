import { useRef, useState } from "react";
import { PostPresenter } from "./PostPresenter";
export function PostContainer({
  // chatModalRef,
  journalModalRef,
}: {
  // chatModalRef: React.RefObject<HTMLIonModalElement>;
  journalModalRef: React.RefObject<HTMLIonModalElement>;
}) {
  const modalRef = useRef<HTMLIonModalElement>(null);

  function openModal() {
    modalRef.current?.present();
  }

  function closeModal() {
    modalRef.current?.dismiss();
  }

  // function openChatModal() {
  //   chatModalRef.current?.present();
  // }

  // function closeChatModal() {
  //   chatModalRef.current?.dismiss();
  // }

  function openJournalModal() {
    journalModalRef.current?.present();
  }

  function closeJournalModal() {
    journalModalRef.current?.dismiss();
  }

  return (
    <PostPresenter
      modalRef={modalRef}
      openModal={openModal}
      closeModal={closeModal}
      // openChatModal={openChatModal}
      // closeChatModal={closeChatModal}
      openJournalModal={openJournalModal}
      closeJournalModal={closeJournalModal}
    />
  );
}
