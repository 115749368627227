import { BaseSyntheticEvent, useEffect, useRef } from "react";
import {
  FormState,
  FieldError,
  ErrorOption,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
  FieldArrayPath,
  FieldArray,
  FieldErrors,
  RegisterOptions,
  UseFormRegisterReturn,
  UseFormReturn,
  Controller,
  Control,
} from "react-hook-form";
import { CardWithForm } from "../../../Home/ui/cardWithForm.tsx/CardWithForm";
import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { mailOpen } from "ionicons/icons";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  rhfMethods: UseFormReturn<any>,
  control: Control<any>,
  name: string,
  errors: FieldErrors<any>,
  onSubmit: SubmitHandler<any>,
  isSuccess: boolean,
  isError: boolean,
  isPending: boolean,
  error: Error | undefined,
}

export const SetDisplayNamePresenter = ({
  rhfMethods,
  control,
  name,
  errors,
  onSubmit,
  isSuccess,
  isError,
  isPending,
  error,

}: Props) => {

  return (

    <IonContent>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonText>
            <h2>What is your display name?</h2>
          </IonText>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <form onSubmit={rhfMethods.handleSubmit(onSubmit)} id="setDisplayName">
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <IonItem>
                  <h1>
                    <IonInput
                      alia-label="Display name"
                      labelPlacement="floating"
                      onIonInput={(e) => onChange(e.detail.value)}
                    ></IonInput>
                  </h1>
                </IonItem>
              )}
            />

            <ErrorMessage
              errors={errors} name={name} />

            <div style={{ bottom: "50%", position: "fixed", right: 0, left: 0 }}>
              <IonButton expand="block" className="ion-margin" disabled={!rhfMethods.formState.isValid} type="submit" id="setDisplayName">Continue</IonButton>
            </div>
          </form>
        </IonRow>
      </IonGrid>

    </IonContent>

  );
};
