import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { AvatarWithSeed } from "../../../globalUi/avatar/AvatarWithSeed";
import { logOutOutline } from "ionicons/icons";

type Props = {
  uid: string;
  displayName: string;
  onClickFunction: React.MouseEventHandler<HTMLIonButtonElement>;
};
export function MePresenter({ uid, displayName, onClickFunction }: Props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Me</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList lines="none">
          <IonItem lines="none">
            <IonGrid>
              <IonRow class="ion-content-center">
                <AvatarWithSeed uid={uid} size="30"></AvatarWithSeed>
                <IonLabel class="ion-padding-start">
                  <IonText>
                    <h1>{displayName}</h1>
                  </IonText>
                </IonLabel>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow class="ion-content-center">
                <IonButton onClick={onClickFunction}>
                  Sign Out
                  <IonIcon icon={logOutOutline}></IonIcon>
                </IonButton>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
}
