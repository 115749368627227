import { useMutation } from "@tanstack/react-query";
import { firebaseProductionConfig } from "../../../../../../globalHook/UseFirebaseApp";
import { initializeApp } from "firebase/app";
import { applyActionCode, getAuth, reload, verifyPasswordResetCode } from "firebase/auth";

export function UseEmailVerification(query: URLSearchParams) {

    async function verifyEmail() {

        const actionCode = query.get("oobCode");

        const config = firebaseProductionConfig;
        const app = initializeApp(config);
        const auth = getAuth(app);
        if (!actionCode) throw new Error("need action code")

        try {
            applyActionCode(auth, actionCode)
            if (auth.currentUser) return reload(auth.currentUser)
        } catch (error) {
            throw error
        }
    }
    return useMutation({ mutationFn: verifyEmail })
}