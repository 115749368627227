import { connectAuthEmulator, getAuth } from "firebase/auth";
import { UseFirebaseApp } from "./UseFirebaseApp";
import { useQuery } from "@tanstack/react-query";

const queryKey = ["UseFirebaseAuth"]
export function UseFirebaseAuth() {
    const { data: app } = UseFirebaseApp()
    async function initializeAuth() {
        if (!app) throw new Error("app is not initialized")
        const auth = getAuth(app)
        if (import.meta.env.MODE === "development") {
            connectAuthEmulator(auth, "http://localhost:9099")
        }
        return auth
    }

    return useQuery({ queryKey: queryKey, queryFn: initializeAuth, enabled: !!app, staleTime: Infinity, gcTime: Infinity })
}