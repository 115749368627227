import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { UseValidGroupId } from "../../hook/UseValidGroupId";
import { Timestamp, addDoc, collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { GroupInvitationType, groupInvitationFormSchema, storedGroupInvitationSchema } from "../../../../../../sharedEntities/groupInvitationType";
import { UseExistInvitationLink } from "./UseExistInvitationLink";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

export function UseCreateLink() {
    const queryClient = useQueryClient()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()

    const {
        data: existInvitationLinkUrl,
        isSuccess: existInvitationLinkUrlIsSuccess,
        isLoading: existInvitationLinkUrlIsLoading,
    } = UseExistInvitationLink();

    const baseUrl = (import.meta.env.MODE === "development" || window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") ? "http://localhost:5174/emailAction?mode=verifyGroupInvitation" : 'https://app.fridge-co.com/emailAction?mode=verifyGroupInvitation'
    async function createLink() {
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')

        if (existInvitationLinkUrl) {
            return existInvitationLinkUrl
        }

        const groupInvitationCollectionRef = collection(db, `version/v1/groups/`, currentGroupId, `groupInvitation`).withConverter(firestoreConverter(groupInvitationFormSchema))
        const groupInvitationDocs = await getDocs(groupInvitationCollectionRef)
        if (groupInvitationDocs.empty) {
            const addedDoc = await addDoc(groupInvitationCollectionRef, { available: true })
            const linkUrl = `${baseUrl}&groupId=${currentGroupId}&oobCode=${addedDoc.id}`
            queryClient.invalidateQueries({ queryKey: ["existInvitationLink", currentGroupId] })
            return linkUrl
        } else {
            const existId = groupInvitationDocs.docs.map((item) => item.id)[0]
            const linkUrl = `${baseUrl}&groupId=${currentGroupId}&oobCode=${existId}`
            queryClient.invalidateQueries({ queryKey: ["existInvitationLink", currentGroupId] })
            return linkUrl
        }
    }

    return useMutation({ mutationFn: createLink })
}