import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRouterLink,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import {
  addCircleOutline,
  chevronForwardOutline,
  personAddOutline,
} from "ionicons/icons";
import { FullyRegisteredUserType } from "../../../../../../sharedEntities";

type Props = {
  rhfMethods: UseFormReturn<any>;
  onSubmitFunction: SubmitHandler<any>;
  membersInfo: { id: string; status: "member" | "pending"; info: FullyRegisteredUserType }[];
  addGroupMemberUrlString: string;
};
export function ManageMembersPresenter({
  rhfMethods,
  onSubmitFunction,
  membersInfo,
  addGroupMemberUrlString,
}: Props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Manage Members</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRouterLink routerLink={addGroupMemberUrlString}>
          <IonChip>
            <IonIcon icon={personAddOutline}></IonIcon>
            <IonText>Add group member</IonText>
            <IonIcon icon={chevronForwardOutline}></IonIcon>
          </IonChip>
        </IonRouterLink>

        <IonList>
          {membersInfo.map((item, index) => (
            <IonItem key={index}>
              {item.info.displayName}
              {item.status === "pending" ? (
                <form onSubmit={rhfMethods.handleSubmit(onSubmitFunction)}>
                  <input
                    hidden
                    {...rhfMethods.register("uid")}
                    value={item.id}
                  ></input>
                  <IonButton type="submit">approve</IonButton>
                </form>
              ) : null}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
}
