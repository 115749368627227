import { IonChip, IonText } from "@ionic/react";
import { ReactionsType } from "./type";
import { ReactEventHandler } from "react";

type Props = {
  reactionsData: ReactionsType;
  onReactionEmojiClick: (
    reactionId: string,
    actionType: "remove" | "add"
  ) => void;
};
export function CommentReactionsPresenter({
  reactionsData,
  onReactionEmojiClick,
}: Props) {
  return (
    <>
      {reactionsData.map((item, index) => (
        <IonChip
          key={index}
          color={item.doesExistCurrentUserIdInMembersIdList ? "primary" : ""}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onReactionEmojiClick(
              item.id,
              item.doesExistCurrentUserIdInMembersIdList ? "remove" : "add"
            );
          }}
        >
          <IonText>{`${item.reactionEmoji} ${item.count}`} </IonText>
        </IonChip>
      ))}
    </>
  );
}
