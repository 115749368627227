import { addDoc, arrayUnion, collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { UseCurrentGroupId } from "../../../../../../../globalHook/useCurrentGroupId";
import { UseCurrentPostId } from "../../../../../../../globalHook/useCurrentPostId";

import { UseFirestoreInitialization } from "../../../../../../../globalHook/UseFirestoreInitialization";
import { firestoreConverter } from "../../../../../../../util/firestoreConverter";
import { ReactionType, storedReactionSchema } from "../../../../../../../../sharedEntities/reactionType";

import { useMutation } from "@tanstack/react-query";
import { UseUserInfo } from "../../../../../../../globalHook/UseUserInfo";
import { fullyRegisteredUserSchema } from "../../../../../../../../sharedEntities";

export function UsePostCommentReaction({ commentId }: { commentId: string }) {
    const { data: currentUser } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: currentPostId } = UseCurrentPostId()

    async function postCommentReaction(formData: { reactionEmoji: string }) {
        if (!currentUser) throw new Error('currentUser error')
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')
        if (!currentPostId) throw new Error('currentPostId error')

        const reactionCollectionRef = collection(db, "version/v1/groups", currentGroupId, "posts", currentPostId, "comments", commentId, "reactions").withConverter(firestoreConverter(storedReactionSchema))

        const sameReactionQuery = query(
            reactionCollectionRef,
            where("reactionEmoji", "==", formData.reactionEmoji)
        );
        const queryResult = await getDocs(sameReactionQuery);

        if (queryResult.empty) {
            return await addDoc(reactionCollectionRef, {
                reactionEmoji: formData.reactionEmoji,
                members: [doc(db, "version/v1/users", currentUser.uid)],
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            });
        }

        const doesDocExist = queryResult.docs[0].exists();
        if (doesDocExist === false) {
            return await addDoc(reactionCollectionRef, {
                reactionEmoji: formData.reactionEmoji,
                members: [doc(db, "version/v1/users", currentUser.uid)],
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            });
        }

        const reactionDocData = queryResult.docs[0].data();
        const reactionDocRef = queryResult.docs[0].ref;
        const memberIds = reactionDocData.members.map((item) => item.id);
        const doesCurrentUserExistInMembers = memberIds.includes(currentUser.uid);
        const currentUserDocRef = doc(
            db,
            "version/v1/users",
            currentUser.uid
        ).withConverter(firestoreConverter(fullyRegisteredUserSchema));

        if (doesCurrentUserExistInMembers) return true;

        return await updateDoc(reactionDocRef, {
            members: arrayUnion(currentUserDocRef),
        });
    }

    return useMutation({ mutationFn: postCommentReaction })


}

