import { DocumentReference, doc, getDoc } from "firebase/firestore";

import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { PostType, FullyRegisteredUserType, storedPostSchema, } from "../../../../../../sharedEntities";
import { FeedItemData } from "../../../GroupFeed/feature/feed/type";
import { useQuery } from "@tanstack/react-query";
import { UseCurrentPostId } from "../../../../../globalHook/useCurrentPostId";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";
import { PostDetailItemDataType } from "./type";
import { UseUserInfo } from "../../../../../globalHook/UseUserInfo";

export function UsePostDetail() {

    const { data: currentUser } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: currentPostId } = UseCurrentPostId()



    async function getPostDetai() {
        if (!currentUser) throw new Error('currentUser error')
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')
        if (!currentPostId) throw new Error('currentGroupId error')

        const postDocRef = doc(db, "version/v1/groups", currentGroupId, "posts", currentPostId).withConverter(firestoreConverter(storedPostSchema))
        const postDocSnapshot = await getDoc(postDocRef)

        if (!postDocSnapshot.exists()) throw new Error("this post does not exist")

        const authorDocRef = postDocSnapshot.data().author;
        const authorDoc = await getDoc(authorDocRef);
        if (!authorDoc.exists()) throw new Error("author error");

        const fromDisplayName = authorDoc.data().displayName;
        if (!fromDisplayName) throw new Error("display name is not set");
        const fromUid = authorDoc.id;

        const messageToUsersDocRef = postDocSnapshot.data().messageTo;
        const messageToUsersInfo = await getMessageToUsersInfo(
            messageToUsersDocRef
        );

        const message = postDocSnapshot.data().message;
        const messageType = postDocSnapshot.data().messageType;
        const createdAtDate = postDocSnapshot.data().createdAt;
        const createdAtDateFormatString =
            createdAtDate.toLocaleDateString();
        const createdAtTimeFormatString =
            createdAtDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            });
        const postDetailUrl = `/g/${currentGroupId}/thread/${postDocSnapshot.id}`;

        const feedItem: PostDetailItemDataType = {
            postId: postDocSnapshot.id,
            message: message,
            fromDisplayName: fromDisplayName,
            fromUid: fromUid,
            messageToUsersInfo: messageToUsersInfo,
            currentUserUid: currentUser.uid,
            messageType: messageType,
            createdAtDate: createdAtDateFormatString,
            createdAtTime: createdAtTimeFormatString,
            threadUrl: postDetailUrl,
        };
        return feedItem;
    }

    return useQuery({ queryKey: ["usePostDetail", currentGroupId, currentPostId], queryFn: getPostDetai, enabled: !!currentUser && !!db && !!currentGroupId && !!currentPostId })


}

export async function getMessageToUsersInfo(
    docRefs: DocumentReference<FullyRegisteredUserType>[]
): Promise<{ displayName: string; uid: string }[]> {
    const unfilteredItems =
        docRefs.length > 0
            ? await Promise.all(
                docRefs.map(async (item) => {
                    const users = await getDoc(item);
                    if (users.exists())
                        return {
                            empty: false,
                            displayName: users.data().displayName,
                            uid: users.id,
                        };
                    else {
                        return { empty: true };
                    }
                })
            )
            : [{ empty: true }];

    const excludeEmptyElement = unfilteredItems.filter(
        (item): item is { empty: false; uid: string; displayName: string } =>
            item.empty === false
    );
    return excludeEmptyElement;
}
