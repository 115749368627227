
import { FirebaseApp, initializeApp } from "firebase/app";
import { UseQState } from "../util/useQState";
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth";
import { Firestore, connectFirestoreEmulator, getFirestore } from "firebase/firestore";

import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";


export const firebaseProductionConfig = {
    apiKey: "AIzaSyCvOgdRzh-A22NSX4sD5vgPfmvfu_1cZcA",
    authDomain: "fridge-ceeb5.firebaseapp.com",
    projectId: "fridge-ceeb5",
    storageBucket: "fridge-ceeb5.appspot.com",
    messagingSenderId: "788151202059",
    appId: "1:788151202059:web:bb39264b4c2471c396f20e",
    measurementId: "G-21EB20KNHD"
}

export function UseFirebaseApp() {

    async function initializeFirebaseApp(): Promise<FirebaseApp | undefined> {
        const app = initializeApp(firebaseProductionConfig)
        if (import.meta.env.MODE === "development") {
            const functions = getFunctions(app)
            connectFunctionsEmulator(functions, "localhost", 5001)
        }
        return app

    }

    return useQuery({
        queryKey: ["UseFirebaseApp"], queryFn: initializeFirebaseApp,
        staleTime: Infinity, gcTime: Infinity,
    })
}
