import { PostModalFabPresenter } from "./PostModalFabPresenter";

type Props = {
  modalRef: React.RefObject<any>;
};

export function PostModalFabContainer({ modalRef }: Props) {
  const openModal = () => {
    modalRef.current?.present();
  };

  return <PostModalFabPresenter openModal={openModal} />;
}
