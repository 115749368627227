import { zodResolver } from "@hookform/resolvers/zod";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

import { PostDetailPresenter } from "./PostDetailPresenter";
import { UsePostDetail } from "./usePostDetail";
import { UseReactionEmojiAdd } from "./useReactionEmojiAdd";
import { FormType, zodSchema } from "./type";
import { IonGrid, IonRow, IonSpinner } from "@ionic/react";

export function PostDetailContainer() {
  const { data: currentGroupId } = UseCurrentGroupId();
  const { data: postDetailData, isLoading } = UsePostDetail();

  const modalRef = useRef<HTMLIonModalElement>(null);

  const rhfMethods = useForm<FormType>({
    resolver: zodResolver(zodSchema),
  });

  const { mutate } = UseReactionEmojiAdd();

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === "emojiSelect") {
      rhfMethods.setValue("reactionEmoji", ev.detail.data);
      rhfMethods.handleSubmit(onSubmitFunction)();
    }
  }
  const openModal = () => {
    modalRef.current?.present();
  };
  const closeModal = () => {
    modalRef.current?.dismiss();
  };

  function onEmojiSelect(data: any, event: any) {
    modalRef.current?.dismiss(data.native, "emojiSelect");
  }

  const onSubmitFunction: SubmitHandler<FormType> = (data, e) => {
    console.log("onSubmitFunction");
    e?.preventDefault();
    mutate(data);
  };

  if (!currentGroupId) return null;
  if (!postDetailData) {
    return (
      <IonGrid>
        <IonRow class="ion-justify-content-center">
          <IonSpinner name="dots" />
        </IonRow>
      </IonGrid>
    );
  }

  return (
    <PostDetailPresenter
      {...postDetailData}
      openModal={openModal}
      closeModal={closeModal}
      onEmojiSelect={onEmojiSelect}
      rhfMethods={rhfMethods}
      modalRef={modalRef}
      onSubmitFunction={onSubmitFunction}
      onWillDismiss={onWillDismiss}
    />
  );
}
