import { DocumentReference } from "firebase/firestore";
import { z } from "zod";
import { FullyRegisteredUserType } from "./userType";
import { documentReferenceSchema, firestoreTimestampSchema } from "./firestoreSchema";

export const reactionSchema = z.object({
    reactionEmoji: z.string().min(1),
})

export type BaseReactionType = z.infer<typeof reactionSchema>

export type UnstoredReactionType = BaseReactionType & {
    members: DocumentReference<FullyRegisteredUserType>[]
}

export const unstoredReactionSchema = reactionSchema.extend({
    members: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>().array())
})

export const storedReactionSchema = unstoredReactionSchema.extend({
    createdAt: firestoreTimestampSchema,
    updatedAt: firestoreTimestampSchema
})

export type StoredReactionType = z.infer<typeof storedReactionSchema>

export type ReactionType = UnstoredReactionType | StoredReactionType

