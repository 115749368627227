import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { UseFirebaseAuthUser } from "../../../globalHook/UseFirebaseAuthUser";
import { UseFirestoreInitialization } from "../../../globalHook/UseFirestoreInitialization";
import { firestoreConverter } from "../../../util/firestoreConverter";
import { fullyRegisteredUserSchema, looseUserTypeSchema, noGroupUserSchema, storedGroupSchema } from "../../../../sharedEntities";
import { useMutation } from "@tanstack/react-query";
import { UseUserInfo } from "../../../globalHook/UseUserInfo";
import { FirebaseError } from "firebase/app";

export function UseSendRequest() {

    const { data: db } = UseFirestoreInitialization()
    const { data: firebaseAuthUser } = UseFirebaseAuthUser()

    async function sendRequest(groupId: string) {
        if (!db) throw new Error("db is not initialized")
        if (!firebaseAuthUser) throw new Error("user is not logged in")

        const targetGroupDocRef = doc(db, "version/v1/groups", groupId).withConverter(firestoreConverter(storedGroupSchema))
        const userDocRef = doc(db, "version/v1/users", firebaseAuthUser.uid).withConverter(firestoreConverter(fullyRegisteredUserSchema))
        try {
            await updateDoc(targetGroupDocRef, { pendingMember: arrayUnion(userDocRef) })
            await updateDoc(userDocRef, { groups: arrayUnion(targetGroupDocRef) })
        } catch (error) {
            if (error instanceof Error || error instanceof FirebaseError) throw error
            else throw new Error("unkown error")
        }


    }

    return useMutation({ mutationKey: ["sendRequest"], mutationFn: sendRequest })

}