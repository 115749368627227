import { IonFab, IonButton, IonIcon, IonLabel } from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";

type Props = {
  openModal: () => void;
};
export function PostModalFabPresenter({ openModal }: Props) {
  return (
    <IonFab slot="fixed" vertical="bottom" horizontal="end">
      <IonButton
        size="small"
        color={"primary"}
        style={{ "--border-radius": "15px" }}
        onClick={() => {
          openModal();
        }}
      >
        <IonIcon icon={addCircleOutline} />
        <IonLabel>Post</IonLabel>
      </IonButton>
    </IonFab>
  );
}
