import { useForm } from "react-hook-form";
import { CreateGroupPresenter } from "./CreateGroupPresenter";
import { GroupNameFormType, groupNameFormSchema } from "../../../../sharedEntities";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseSendGroupName } from "./UseSendGroupName";
import { FirebaseError } from "firebase/app";

export function CreateGroupContainer() {

    const rhfMethods = useForm<GroupNameFormType>({
        resolver: zodResolver(groupNameFormSchema)
    })
    const { mutate, isSuccess, isError, error } = UseSendGroupName()

    const onSubmit = rhfMethods.handleSubmit((data, e) => {
        e?.preventDefault()
        mutate(data)
    })



    return <CreateGroupPresenter
        onSubmit={onSubmit}
        control={rhfMethods.control}
        errors={rhfMethods.formState.errors}
        isError={isError}
        error={error instanceof FirebaseError || error instanceof Error ? error : undefined}
        buttonDisabled={!rhfMethods.formState.isValid}
        name="name"
    />
}