import { useQuery } from "@tanstack/react-query";
import { UseUserType } from "./UseUserType";

export function UseUserInfo() {
    const { data: userTypeData, isSuccess } = UseUserType()
    const queryKey = ["userInfo", userTypeData?.isRegistered]
    async function getUserInfo() {
        if (!userTypeData?.isRegistered) throw new Error("userTypeData error")
        const { isRegistered, userPattern: userType, ...rest } = userTypeData
        const userInfo = rest
        return userInfo
    }
    return useQuery({ queryKey, queryFn: getUserInfo, enabled: isSuccess && userTypeData?.isRegistered })
}