import {
  IonChip,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { AvatarWithSeed } from "../../../../../../../globalUi/avatar/AvatarWithSeed";
import { CommentItemType } from "./type";
import { CommentReactionsContainer } from "../commentReactions/CommentReactionsContainer";
import { PostCommentReactionContainer } from "../../feature/postCommentReaction/PostCommentReactionContainer";

export function CommentItemPresenter({
  commentId,
  fromUid,
  fromDisplayName,
  message,
}: CommentItemType) {
  return (
    <IonItem className="ion-wrap">
      <IonGrid fixed class="ion-padding">
        <IonRow>
          <AvatarWithSeed uid={fromUid} size="18"></AvatarWithSeed>
          <IonText class="ion-padding-start">{fromDisplayName}</IonText>
        </IonRow>
        <IonRow class="ion-padding-vertical">
          <IonLabel class="ion-text-wrap" style={{ overflowWrap: "anywhere" }}>
            {message}
          </IonLabel>
        </IonRow>
        <IonRow>
          <CommentReactionsContainer commentId={commentId} />
          <PostCommentReactionContainer commentId={commentId} />
        </IonRow>
      </IonGrid>
    </IonItem>
  );
}
