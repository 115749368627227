import { useQuery } from "@tanstack/react-query";
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { UseValidGroupId } from "../../hook/UseValidGroupId";
import { GroupType, FullyRegisteredUserType, storedGroupSchema } from "../../../../../../sharedEntities";
import { doc, getDoc } from "firebase/firestore";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

export function UseGroupMembersInfo() {
  const { data: currentGroupId } = UseCurrentGroupId();

  const { data: db } = UseFirestoreInitialization();
  async function getGroupMembersInfo(): Promise<
    { id: string; status: "pending" | "member"; info: FullyRegisteredUserType }[]
  > {
    if (!db) throw new Error("db error");
    if (!currentGroupId) throw new Error("currentGroupId error");

    const groupDocRef = doc(
      db,
      "version/v1/groups",
      currentGroupId
    ).withConverter(firestoreConverter(storedGroupSchema));
    const groupDoc = await getDoc(groupDocRef);
    if (!groupDoc.exists()) throw new Error("group doc does not exist");

    const memberDocRefs = groupDoc.data().member;
    const pendingMemberDocRefs = groupDoc.data().pendingMember;

    const memberDocs = await Promise.all(
      memberDocRefs.map(async (item) => {
        const docs = await getDoc(item);
        if (!docs.exists()) return { empty: true };
        return {
          empty: false,
          id: docs.id,
          info: docs.data(),
          status: "member",
        };
      })
    );

    const filteredMemberDoc = memberDocs
      .filter(
        (
          item
        ): item is {
          empty: false;
          id: string;
          status: "member";
          info: FullyRegisteredUserType;
        } => item.empty === false
      )
      .map((item) => {
        return { id: item.id, status: item.status, info: item.info };
      });

    const pendingMemberDocs = await Promise.all(
      pendingMemberDocRefs.map(async (item) => {
        const docs = await getDoc(item);
        if (!docs.exists()) return { empty: true };
        return {
          empty: false,
          id: docs.id,
          info: docs.data(),
          status: "pending",
        };
      })
    );

    const filteredPendingMemberDoc = pendingMemberDocs
      .filter(
        (
          item
        ): item is {
          empty: false;
          id: string;
          status: "pending";
          info: FullyRegisteredUserType;
        } => item.empty === false
      )
      .map((item) => {
        return { id: item.id, status: item.status, info: item.info };
      });

    return [...filteredMemberDoc, ...filteredPendingMemberDoc];
  }

  return useQuery({
    queryKey: ["groupMembersInfo", currentGroupId],
    queryFn: getGroupMembersInfo,
    enabled: !!db && !!currentGroupId,
  });
}
