import { useEffect, useMemo } from "react";
import { Redirect, useLocation } from "react-router";
import { UseGroupInvitationVerification } from "../hook/UseGroupInvitationVerification";
import { VerifyGroupInvitationPresenter } from "../presenter/VerifyGroupInvitationPresenter";
import { FirebaseError } from "firebase/app";

type Props = {
  redirectPath: string;
};
export function VerifyGroupInvitationContainer({ redirectPath }: Props) {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { data, isSuccess, isLoading, isError, error } =
    UseGroupInvitationVerification(query);
  if (isSuccess) return (<Redirect to={redirectPath} />)
  else return (
    <VerifyGroupInvitationPresenter
      data={isSuccess ? data : { result: false }}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={
        error instanceof FirebaseError || error instanceof Error
          ? error
          : undefined
      }
    ></VerifyGroupInvitationPresenter>
  );
}
