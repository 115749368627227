import { RouteComponentProps } from "react-router";
import { WelcomeContainer } from "../../components/feature/Welcome/WelcomeContainer";
import { UserPattern } from "../../globalHook/UseUserType";

interface WelcomePageProps extends RouteComponentProps<{}> {
}

export function Welcome({ }: WelcomePageProps) {
    console.log("WelcomePageProps")
    return <WelcomeContainer />;
}
