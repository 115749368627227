import { UseCurrentGroupId } from "../../../../../../../globalHook/useCurrentGroupId";
import { ReactionsPresenter } from "./ReactionsPresenter";
import { UseReactionAdd } from "./useReactionAdd";
import { UseReactionRemove } from "./useReactionRemove";
import { UseRealtimeReactions } from "./useRealtimeReactions";

export type Props = { postId: string };
export function ReactionsContainer({ postId }: Props) {
  const { data: reactionsData, isSuccess } = UseRealtimeReactions({ postId });
  const { mutate: removeReaction } = UseReactionRemove();
  const { mutate: addReaction } = UseReactionAdd();

  function onReactionEmojiClick(
    reactionId: string,
    actionType: "remove" | "add"
  ) {
    if (actionType === "remove") removeReaction({ postId, reactionId });
    if (actionType === "add") addReaction({ postId, reactionId });
  }

  if (isSuccess)
    return (
      <ReactionsPresenter
        reactionsData={reactionsData.reactions}
        onReactionEmojiClick={onReactionEmojiClick}
      />
    );
  return null;
}
