import { ErrorMessage } from "@hookform/error-message";
import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import { UseFormReturn, useFormContext } from "react-hook-form";
import { NonEmptyString } from "../../../../../util/typeUtility";
import { FullyRegisteredUserType, GroupType, } from "../../../../../../sharedEntities";

type Props = {
  inputRegisterName: keyof GroupType | keyof FullyRegisteredUserType;
  inputLabel: keyof GroupType | keyof FullyRegisteredUserType;
  inputProps: React.ComponentProps<typeof IonInput>;
};

export const FormInputItem = ({
  inputRegisterName,
  inputLabel,
  inputProps,
}: Props) => {
  const { formState, register } = useFormContext();
  return (
    <IonItem
      className={
        formState.errors[inputRegisterName] ? "ion-invalid" : undefined
      }
    >
      <IonInput
        {...inputProps}
        label={inputLabel}
        {...register(inputRegisterName)}
      ></IonInput>
      {formState.errors[inputRegisterName]?.message ? (
        <IonNote slot="error">
          <ErrorMessage
            errors={formState.errors}
            name={inputRegisterName}
          ></ErrorMessage>
        </IonNote>
      ) : null}
    </IonItem>
  );
};
