import { useMutation, useQueryClient } from "@tanstack/react-query";

export function UseSetCurrentGroupId() {

    const queryClient = useQueryClient()

    // const { data: currentUser } = UseCurrentUser()
    // const { data: db } = UseFirestoreInitialization()

    async function setCurrentGroupId(targetGroupId: string) {

        // if (!currentUser) throw new Error('currentUser error')
        // if (!db) throw new Error('db error')


        // const currentUserDocRef = doc(db, "version/v1/users", currentUser.uid).withConverter(firestoreConverter<UserType>())
        // const currentUserDocSnapshot = await getDoc(currentUserDocRef)
        // if (!currentUserDocSnapshot.exists()) throw new Error("current user doc doesnt exist")

        // const groups = currentUserDocSnapshot.data().groups
        // const groupsId = groups.map((item) => item.id)
        // const doesExistTargetGroupInUsersGroupsIdList = groupsId.includes(targetGroupId)

        // if (!doesExistTargetGroupInUsersGroupsIdList) throw new Error("this group id does not exist in current user groups list")

        // const targetGroupDocRef = doc(db, "version/v1/groups", targetGroupId).withConverter(firestoreConverter<GroupType>())
        // const targetGroupDocSnapshot = await getDoc(targetGroupDocRef)
        // if (!targetGroupDocSnapshot.exists()) throw new Error("this group does not exist")

        queryClient.setQueryData(["useCurrentGroupId"], targetGroupId)


    }

    return useMutation({ mutationFn: setCurrentGroupId })
}
