import { OverlayEventDetail } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";

import { addOutline, happyOutline } from "ionicons/icons";
import { UseFormReturn } from "react-hook-form";

type Props = {
  rhfMethods: UseFormReturn<any>;
  modalRef: React.RefObject<HTMLIonModalElement>;
  openModal: () => void;
  closeModal: () => void;
  onWillDismiss: (ev: CustomEvent<OverlayEventDetail<any>>) => void;
  onEmojiSelect: (data: any, event: any) => void;
};
export function PostCommentReactionPresenter({
  rhfMethods,
  modalRef,
  openModal,
  onWillDismiss,
  closeModal,
  onEmojiSelect,
}: Props) {
  return (
    <>
      <IonChip
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openModal();
        }}
      >
        <IonIcon
          icon={happyOutline}
          style={{ margin: 0 }}
          size="small"
        ></IonIcon>
        <IonIcon icon={addOutline} style={{ margin: 0 }} size="small"></IonIcon>
      </IonChip>
      <IonModal
        ref={modalRef}
        handle={true}
        onWillDismiss={(ev) => onWillDismiss(ev)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={closeModal}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent scrollY={false} class="ion-padding">
          <form>
            <IonGrid>
              <IonRow class="ion-justify-content-center">
                <Picker data={data} onEmojiSelect={onEmojiSelect}></Picker>
              </IonRow>
            </IonGrid>
          </form>
        </IonContent>
      </IonModal>
    </>
  );
}
