import { IonNote, IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { CardWithForm } from "../../../Home/ui/cardWithForm.tsx/CardWithForm";
import { Control, Controller, FieldErrors, SubmitHandler, UseFormReturn } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FirebaseError } from "firebase/app";

type Props = {
  rhfMethods: UseFormReturn<any>,
  control: Control<any>,
  name: string,
  errors: FieldErrors<any>,
  onSubmit: SubmitHandler<any>,
  isSuccess: boolean,
  isError: boolean,
  isLoading: boolean,
  error: Error | undefined,
}

export const GroupNameSettingPresenter = ({
  rhfMethods,
  control,
  name,
  errors,
  onSubmit,
  isSuccess,
  isError,
  isLoading,
  error,
}: Props) => {
  console.log({ errors, error, rhfMethods })
  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonText>
            <h2>Join or Create Group!</h2>
          </IonText>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonText>
            <h6>If you have an invite key, please enter it below</h6>
          </IonText>
        </IonRow>
        <form onSubmit={rhfMethods.handleSubmit(onSubmit)} id="setDisplayName">
          <IonRow className="ion-justify-content-center">

            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <IonItem>
                  <h1>
                    <IonInput
                      alia-label="Display name"
                      labelPlacement="floating"
                      onIonInput={(e) => onChange(e.detail.value)}
                    ></IonInput>
                  </h1>
                </IonItem>
              )}
            />
          </IonRow>
          <IonRow>
            <ErrorMessage
              errors={errors} name={name} />
            {isError &&
              (error instanceof FirebaseError || error instanceof Error) ? (
              <IonRow>
                <IonNote color={"danger"}>{error.message}</IonNote>
              </IonRow>
            ) : null}
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <IonButton expand="block" className="ion-margin" disabled={!rhfMethods.formState.isValid} type="submit" id="setDisplayName">Continue</IonButton>
            </IonCol>

          </IonRow>
        </form>
        <IonRow className="ion-justify-content-center">

          <IonText>
            <h6>or</h6>
          </IonText>
        </IonRow>
        <IonRow className="ion-justify-content-center">

          <IonButton color="dark" routerLink="/createGroup">Create a new group</IonButton>


        </IonRow>
      </IonGrid>

    </IonContent>


  );
};
