import { ZodSchema, z } from "zod";
import { reactionSchema } from "../../../../../../sharedEntities/reactionType";
import { ReactElement } from "react";


export const zodSchema = reactionSchema.pick({ reactionEmoji: true })

export type FormType = z.infer<typeof zodSchema>

export type PostDetailItemDataType = {

    postId: string,
    message: string | ReactElement;
    fromDisplayName: string;
    fromUid: string,
    messageToUsersInfo: { displayName: string, uid: string }[]
    currentUserUid: string
    messageType: "✉️" | "😘" | "📓",
    createdAtDate: string,
    createdAtTime: string,
    threadUrl: string,
}