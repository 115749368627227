import {
  IonButton,
  IonInput,
  IonItem,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { Controller, UseFormReturn } from "react-hook-form";
import { VerifyEmailContainer } from "./feature/verifyEmail/container/VerifyEmailContainer";
import { ResetPasswordContainer } from "./feature/resetPassword/ResetPasswordContainer";
import { VerifyGroupInvitationContainer } from "./feature/verifyGroupInvitation/container/VerifyGroupInvitationContainer";
type Props = {
  mode: string | null;
  redirectPath: string;
};
export function EmailActionPresenter({ mode, redirectPath }: Props) {
  if (mode === "verifyEmail") return <VerifyEmailContainer />;
  if (mode === "resetPassword") return <ResetPasswordContainer />;
  if (mode === "verifyGroupInvitation")
    return <VerifyGroupInvitationContainer redirectPath={redirectPath} />;
  return (
    <IonPage>
      <IonItem>This code is invalid</IonItem>
    </IonPage>
  );
}
