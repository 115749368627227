import {
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { User } from "firebase/auth";
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  Unsubscribe,
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import {
  PostType,
  FullyRegisteredUserType,
  storedPostSchema,
} from "../../../../../../sharedEntities";
import { FeedItemData } from "./type";
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";
import { UseUserInfo } from "../../../../../globalHook/UseUserInfo";

export function UseRealtimeFeed(messageType?: string) {
  const queryClient = useQueryClient();

  const { data: currentGroupId } = UseCurrentGroupId();
  const { data: userInfo } = UseUserInfo();
  const { data: db } = UseFirestoreInitialization();
  const queryKey = ["useRealtimeFeed", currentGroupId];

  const getRealtimeFeed = async (): Promise<{
    items: FeedItemData[];
    hasMore: boolean;
    unsubscribe: Unsubscribe | undefined;
  }> => {
    if (!currentGroupId) throw new Error("currentGroupId error");
    if (!userInfo) throw new Error("userInfo error");
    if (!db) throw new Error("db error");

    let firstRun = true;

    const postCollectionRef = collection(
      db,
      "version/v1/groups",
      currentGroupId,
      "posts"
    ).withConverter(firestoreConverter(storedPostSchema));

    const currentItemsLength = queryClient.getQueryData<{
      items: FeedItemData[];
      hasMore: boolean;
      unsubscribe: Unsubscribe | undefined;
    }>(queryKey)?.items.length;

    const limitNumber = currentItemsLength ? currentItemsLength + 20 : 30;

    const visiblePostItemsQuery = await getVisiblePostItemsQuery(
      limitNumber,
      postCollectionRef,
      db,
      userInfo,
      messageType
    );

    const data = new Promise<{
      hasMore: boolean;
      items: FeedItemData[];
      unsubscribe: Unsubscribe;
    }>(async (resolve, reject) => {
      const unsubscribe = onSnapshot(
        visiblePostItemsQuery,
        async (querySnapshot) => {
          const hasMore = querySnapshot.size < limitNumber ? false : true;

          const items = await Promise.all(
            querySnapshot.docs.map(async (snapshotDoc) => {
              if (!snapshotDoc.exists()) throw new Error("snapshotDoc error");

              const message = snapshotDoc.data().message;
              const messageType = snapshotDoc.data().messageType;
              const createdAtDate = snapshotDoc.data().createdAt;
              const createdAtDateFormatString =
                createdAtDate.toLocaleDateString();
              const createdAtTimeFormatString =
                createdAtDate.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                });
              const postDetailUrl = `/g/${currentGroupId}/thread/${snapshotDoc.id}`;

              const authorDocRef = snapshotDoc.data().author;
              const authorDoc = await getDoc(authorDocRef);
              if (!authorDoc.exists()) throw new Error("author error");

              const fromDisplayName = authorDoc.data().displayName;
              const fromUid = authorDoc.data().uid;

              const messageToUsersDocRef = snapshotDoc.data().messageTo;
              const messageToUsersInfo = await getMessageToUsersInfo(
                messageToUsersDocRef
              );

              const feedItem = {
                postId: snapshotDoc.id,
                message: message,
                fromDisplayName: fromDisplayName,
                fromUid: fromUid,
                messageToUsersInfo: messageToUsersInfo,
                currentUserUid: userInfo.uid,
                messageType: messageType,
                createdAtDate: createdAtDateFormatString,
                createdAtTime: createdAtTimeFormatString,
                threadUrl: postDetailUrl,
              };

              return feedItem;
            })
          ); //items

          if (firstRun) {
            resolve({
              items: items.slice().reverse(),
              hasMore: hasMore,
              unsubscribe: unsubscribe,
            });
            firstRun = false;
          } else {
            queryClient.setQueryData(queryKey, {
              items: items.slice().reverse(),
              hasMore: hasMore,
              unsubscribe: unsubscribe,
            });
          }
        },

        (error) => {
          if (firstRun) {
            reject(error);
            firstRun = false;
          } else {
            queryClient.invalidateQueries({ queryKey });
          }
        }
      );
    });

    const previousData = queryClient.getQueryData<{
      items: FeedItemData[];
      hasMore: boolean;
      lastItemId: string | undefined;
      unsubscribe: Unsubscribe | undefined;
    }>(queryKey);

    previousData?.unsubscribe && previousData.unsubscribe();

    return data;
  };

  return useQuery({
    queryKey: queryKey,
    enabled: !!userInfo && !!db && !!currentGroupId,
    queryFn: () => getRealtimeFeed(),
    gcTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

export async function getVisiblePostItemsQuery(
  limitNumber: number,
  postCollectionRef: CollectionReference<PostType>,
  db: Firestore,
  userInfo: FullyRegisteredUserType,
  messageType?: string
) {
  const messageTypeWhereClause = messageType
    ? where("messageType", "==", messageType)
    : undefined;

  const queryConstraints = [
    orderBy("createdAt", "desc"),
    where("visible", "array-contains-any", [
      doc(db, "version/v1/users/", userInfo.uid),
      "everyoneInGroups",
    ]),
    messageTypeWhereClause,
    limit(limitNumber),
  ].filter(
    (
      item
    ): item is
      | QueryOrderByConstraint
      | QueryFieldFilterConstraint
      | QueryLimitConstraint => item !== undefined
  );

  const visiblePostItemsQuery = query(postCollectionRef, ...queryConstraints);

  return visiblePostItemsQuery;
}

export async function getMessageToUsersInfo(
  docRefs: DocumentReference<FullyRegisteredUserType>[]
): Promise<{ displayName: string; uid: string }[]> {
  const unfilteredItems =
    docRefs.length > 0
      ? await Promise.all(
        docRefs.map(async (item) => {
          const users = await getDoc(item);
          if (users.exists())
            return {
              empty: false,
              displayName: users.data().displayName,
              uid: users.id,
            };
          else {
            return { empty: true };
          }
        })
      )
      : [{ empty: true }];

  const excludeEmptyElement = unfilteredItems.filter(
    (item): item is { empty: false; uid: string; displayName: string } =>
      item.empty === false
  );
  return excludeEmptyElement;
}
