import { Redirect, RouteComponentProps } from "react-router";
import { InviteContainer } from "../../components/feature/Invite/InviteContainer";
import { UseVerifyInvitation } from "../../components/feature/Invite/UseVerifyInvitation";
import { UseSetReffererGroupId } from "../../globalHook/UseSetReffererGroupId";
interface InvitePageProps extends RouteComponentProps<{
    groupId: string;
    invitationKey: string;
}> {
    redirectPath: string;
}

export function Invite({ match, redirectPath }: InvitePageProps) {
    const { data, isSuccess, isPending, isError, error } = UseVerifyInvitation(match.params.groupId, match.params.invitationKey)
    const { mutate } = UseSetReffererGroupId()
    if (isSuccess) mutate(data.validGroupId)

    if (isPending) return <div>loading...</div>
    if (isError) return <div>{error.message}</div>
    if (isSuccess) return <Redirect to={redirectPath} />
    return null
}
