import { z } from "zod";
import { firestoreTimestampSchema } from "./firestoreSchema";

export const unstoredNotificationTokenSchema = z.object({
    token: z.string(),
    webOrNative: z.union([z.literal("web"), z.literal("native"), z.literal("noneOfThem")]),
    platform: z.union([z.literal("android"), z.literal("ios"), z.literal("unknown")]),
})

export type UnstoredNotificationTokenType = z.infer<typeof unstoredNotificationTokenSchema>

export const storedNotificationTokenSchema = unstoredNotificationTokenSchema.extend({
    createdAt: firestoreTimestampSchema,
    updatedAt: firestoreTimestampSchema,
})

export type StoredNotificationTokenType = z.infer<typeof storedNotificationTokenSchema>

export type NotificationTokenType = UnstoredNotificationTokenType | StoredNotificationTokenType