import { useMutation } from "@tanstack/react-query";
import { getAuth, signOut } from "firebase/auth";

export function UseSignOut() {
    async function signOutFromFirebaseAuth() {
        const auth = getAuth()
        if (!auth) throw new Error("auth error")
        return await signOut(auth)
    }
    return useMutation({ mutationFn: signOutFromFirebaseAuth })
}