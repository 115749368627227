import { DocumentReference } from "firebase/firestore"
import { FullyRegisteredUserType } from "./userType"
import { z } from "zod"
import { documentReferenceSchema } from "./firestoreSchema"


export const commentSchema = z.object({
    title: z.string().min(1),
    subtitle: z.string().min(1),
    message: z.string().min(1),
})
export const unstoredBaseCommentSchema = commentSchema.extend({})

export type UnstoredCommentType =
    Omit<z.infer<typeof unstoredBaseCommentSchema>, | "messageToIds">
    & {
        author: DocumentReference<FullyRegisteredUserType>
        visible: DocumentReference<FullyRegisteredUserType>[] | ["everyoneInGroups"]
    }

export const unstoredCommentSchema: z.ZodType<UnstoredCommentType> = unstoredBaseCommentSchema.extend({
    author: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>()),
    visible: z.lazy(() => z.union([documentReferenceSchema<FullyRegisteredUserType>().array(), z.tuple([z.literal("everyoneInGroups")])])),
})

export const storedBaseCommentSchema = commentSchema.extend({
    createdAt: z.date(),
    updatedAt: z.date()
})

export type StoredCommentType =
    Omit<z.infer<typeof storedBaseCommentSchema>, "messageToIds">
    & {
        author: DocumentReference<FullyRegisteredUserType>
        visible: DocumentReference<FullyRegisteredUserType>[] | ["everyoneInGroups"]
    }

export const storedCommentSchema: z.ZodType<StoredCommentType> = storedBaseCommentSchema.extend({
    author: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>()),
    visible: z.lazy(() => z.union([documentReferenceSchema<FullyRegisteredUserType>().array(), z.tuple([z.literal("everyoneInGroups")])])),
})


export type CommentType = StoredCommentType  