import { ErrorMessage } from "@hookform/error-message";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import {
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  UseFormWatch,
  useFormContext,
} from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { FormInputItem } from "../formInputItem/FormInputItem";
import { GroupType, FullyRegisteredUserType } from "../../../../../../sharedEntities";

type Props = {
  rhfMethods: UseFormReturn<any>;
  onSubmitFunction: SubmitHandler<any>;
  title: string;
  subtitle: string;
  content: string | React.Component;
  isPending: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: Error | FirebaseError | undefined;
  inputs: {
    inputRegisterName: any;
    inputLabel: any;
    inputProps: any;
  }[];
  buttonLabel: string;
  buttonProps: any;
};

export const CardWithForm = ({
  rhfMethods,
  title,
  subtitle,
  content,
  onSubmitFunction,
  isSuccess,
  isError,
  isPending,
  error,
  inputs,
  buttonLabel,
  buttonProps,
}: //   ...props
  Props) => {
  return (
    <IonCard disabled={isSuccess}>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
        <IonCardSubtitle>{subtitle}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow className="ion-margin-bottom">
            <>{content}</>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <FormProvider {...rhfMethods}>
              <form onSubmit={rhfMethods.handleSubmit(onSubmitFunction)} >
                {inputs.map((inputItem, index) => {
                  return <FormInputItem {...inputItem} key={index} />;
                })}

                <IonButton
                  type="submit"
                  expand="block"
                  className="ion-margin-top"
                  {...buttonProps}
                  disabled={isPending}
                >
                  {isPending ? (
                    <IonSpinner></IonSpinner>
                  ) : isSuccess ? (
                    "done!"
                  ) : (
                    buttonLabel
                  )}
                </IonButton>
              </form>
            </FormProvider>

          </IonRow>

          {isError &&
            (error instanceof FirebaseError || error instanceof Error) ? (
            <IonNote color="danger">{error.message}</IonNote>
          ) : null}
        </IonGrid>

      </IonCardContent>
    </IonCard>
  );
};
