import {
  IonItem,
  IonGrid,
  IonRow,
  IonText,
  IonCol,
  IonRouterLink,
  IonLabel,
  IonIcon,
  IonBadge,
  IonChip,
  IonModal,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { arrowForward, happyOutline, addOutline } from "ionicons/icons";
import { UseFormReturn, SubmitHandler } from "react-hook-form";
import { AvatarWithSeed } from "../../../../../globalUi/avatar/AvatarWithSeed";
import { FeedItemData } from "../../../GroupFeed/feature/feed/type";
import { ReactionsContainer } from "../../../GroupFeed/feature/feed/ui/reactions/ReactionsContainer";
import { CommentListContainer } from "../../feature/commentList/CommentListContainer";
import { PostDetailItemDataType } from "./type";

type Props = PostDetailItemDataType & {
  openModal: () => void;
  closeModal: () => void;
  onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => void;
  modalRef: React.RefObject<HTMLIonModalElement>;
  rhfMethods: UseFormReturn<any>;
  onSubmitFunction: SubmitHandler<any>;
  onEmojiSelect: (data: any, event: any) => void;
};

export function PostDetailPresenter({
  message,
  fromDisplayName,
  fromUid,
  messageToUsersInfo,
  currentUserUid,
  messageType,
  createdAtDate,
  createdAtTime,
  postId,
  threadUrl,
  openModal,
  closeModal,
  onWillDismiss,
  modalRef,
  rhfMethods,
  onSubmitFunction,
  onEmojiSelect,
}: Props) {
  return (
    <IonItem detail={false}>
      <IonGrid class="ion-padding-none" fixed>
        <IonRow class="ion-justify-content-end">
          <IonText>
            <div style={{ fontSize: "12px" }}>{createdAtTime}</div>
          </IonText>
        </IonRow>

        <IonRow class="ion-align-items-center ion-justify-content-start">
          <IonCol size="auto">
            <IonRouterLink routerLink={fromUid === currentUserUid ? "/me" : ""}>
              <IonRow class="ion-align-items-center">
                <AvatarWithSeed uid={fromUid} size="12"></AvatarWithSeed>
                <IonLabel>
                  <IonText>
                    <div style={{ marginLeft: "4px" }}>{fromDisplayName}</div>
                  </IonText>
                </IonLabel>
              </IonRow>
            </IonRouterLink>
          </IonCol>

          {messageToUsersInfo.length > 0 ? (
            <>
              <IonCol size="auto">
                <IonRow class="ion-align-items-center">
                  <IonIcon icon={arrowForward} size="small"></IonIcon>
                </IonRow>
              </IonCol>

              {messageToUsersInfo.map((info, index) => (
                <IonCol key={index} size="auto">
                  <IonRouterLink
                    routerLink={info.uid === currentUserUid ? "/me" : ""}
                  >
                    <IonRow class="ion-align-items-center">
                      <AvatarWithSeed uid={info.uid} size="12"></AvatarWithSeed>
                      <IonLabel> {info.displayName}</IonLabel>
                    </IonRow>
                  </IonRouterLink>
                </IonCol>
              ))}
            </>
          ) : null}
        </IonRow>
        <IonRow class=" ion-padding-vertical ion-align-items-center">
          <IonBadge color={"light"}>
            <h1 style={{ margin: "4px" }}>{messageType}</h1>
          </IonBadge>
          <IonLabel>
            <IonText class="ion-text-wrap ion-padding-start">{message}</IonText>
          </IonLabel>
        </IonRow>
        <IonRow>
          <ReactionsContainer postId={postId} />
          <IonChip
            onClick={(e) => {
              console.log("onClick");
              e.preventDefault();
              e.stopPropagation();
              openModal();
            }}
          >
            <IonIcon
              icon={happyOutline}
              style={{ margin: 0 }}
              size="small"
            ></IonIcon>
            <IonIcon
              icon={addOutline}
              style={{ margin: 0 }}
              size="small"
            ></IonIcon>
          </IonChip>
          <IonModal
            ref={modalRef}
            handle={true}
            onWillDismiss={(ev) => onWillDismiss(ev)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={closeModal}>Cancel</IonButton>
              </IonButtons>
            </IonToolbar>
            <IonContent
              scrollY={false}
              class="ion-padding"
              onClick={(e) => {
                console.log("onClick");
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <form onSubmit={rhfMethods.handleSubmit(onSubmitFunction)}>
                <IonGrid>
                  <IonRow class="ion-justify-content-center">
                    <Picker data={data} onEmojiSelect={onEmojiSelect}></Picker>
                  </IonRow>
                </IonGrid>
              </form>
            </IonContent>
          </IonModal>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
}
