
import { UseFirestoreInitialization } from "../../../globalHook/UseFirestoreInitialization"
import { UseUserInfo } from "../../../globalHook/UseUserInfo"
import { UseFirebaseAuth } from "../../../globalHook/UseFirebaseAuth"
import { doc, getDoc } from "firebase/firestore"
import { storedGroupInvitationSchema } from "../../../../sharedEntities/groupInvitationType"
import { firestoreConverter } from "../../../util/firestoreConverter"
import { FirebaseError } from "firebase/app"
import { useQuery } from "@tanstack/react-query"

export function UseVerifyInvitation(groupId: string, invitationKey: string) {
    const { data: db } = UseFirestoreInitialization()
    const { data: auth } = UseFirebaseAuth()

    async function verifyInvitation(): Promise<({ validGroupId: string, validInvitationKey: string })> {
        if (!auth) throw new Error("auth error")
        if (!db) throw new Error("db error")
        if (!groupId) throw new Error("groupId empty")
        if (!invitationKey) throw new Error('invitationCode error')


        const groupInvitationDocRef = doc(db, `version/v1/groups/`, groupId, `groupInvitation`, invitationKey).withConverter(firestoreConverter(storedGroupInvitationSchema))


        const groupInvitationDoc = await getDoc(groupInvitationDocRef)
        if (!groupInvitationDoc.exists()) throw new Error("this invitation code does not exist")
        console.log("invitation code exists")
        const isValid = groupInvitationDoc.data().available === true

        if (!isValid) throw new Error("code is not valid")
        return { validGroupId: groupId, validInvitationKey: invitationKey }



    }

    return useQuery({ queryKey: ["groupInvitationVerification"], queryFn: verifyInvitation, enabled: !!db, refetchOnWindowFocus: false })
}