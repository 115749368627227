import { RouteComponentProps } from "react-router";
import { EmailActionContainer } from "../../components/feature/EmailAction/EmailActionContainer";
import { useMemo } from "react";
interface EmailActionPageProps extends RouteComponentProps<{}> {
  redirectPath: string;
}

export function EmailAction({ location, redirectPath }: EmailActionPageProps) {
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const mode = query.get("mode");
  return <EmailActionContainer mode={mode} redirectPath={redirectPath} />;
}
