import { FirebaseError, initializeApp } from "firebase/app";
import { applyActionCode, getAuth } from "firebase/auth";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { EmailActionPresenter } from "./EmailActionPresenter";
import { firebaseProductionConfig } from "../../../globalHook/UseFirebaseApp";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseResetPassword } from "./feature/resetPassword/useResetPassword";
type Props = { mode: string | null, redirectPath: string };

export function EmailActionContainer({ mode, redirectPath }: Props) {
  return <EmailActionPresenter mode={mode} redirectPath={redirectPath} />;
}
