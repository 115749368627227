import { SubmitHandler, useForm } from "react-hook-form";
import { PostCommentPresenter } from "./PostCommentPresenter";
import { FormType, zodSchema } from "./type";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseCommentPost } from "./useCommentPost";
import { useRef } from "react";
export function PostCommentContainer() {
  const modalRef = useRef<HTMLIonModalElement>(null);

  const rhfMethods = useForm<FormType>({
    resolver: zodResolver(zodSchema),
  });

  const { mutate } = UseCommentPost();
  const onSubmit: SubmitHandler<FormType> = (data, e) => {
    e?.preventDefault();
    mutate(data, {
      onSuccess: () => {
        rhfMethods.reset();
      },
    });
  };
  return (
    <PostCommentPresenter
      rhfMethods={rhfMethods}
      onSubmit={onSubmit}
      modalRef={modalRef}
    />
  );
}
