import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseSetGroupName } from "./UseSetGroupName";
import { GroupNameSettingPresenter } from "./GroupNameSettingPresenter";
import { FirebaseError } from "firebase/app";
import { UseVerifyInvitationKey } from "./UseVerifyInvitationKey";
import { z } from "zod";
import { UseVerifyInvitation } from "../../../Invite/UseVerifyInvitation";
import { UseReffererGroupId } from "../../../../../globalHook/UseReffererGroupId";
import { useEffect } from "react";
import { Redirect } from "react-router";

export const GroupNameSettingContainer = () => {
  const { data: reffererGroupId } = UseReffererGroupId()

  const verifyInvitationKeyFormSchema = z.object({
    invitationKey: z.string().min(1),
  })

  type FormType = z.infer<typeof verifyInvitationKeyFormSchema>;
  const rhfMethods = useForm<FormType>({ resolver: zodResolver(verifyInvitationKeyFormSchema) });
  const { mutate, isError, isSuccess, isPending, error } =
    UseVerifyInvitationKey();

  const onSubmit: SubmitHandler<FormType> = (data, e) => {
    e?.preventDefault();
    console.log({ data })
    mutate(data);
  };

  if (reffererGroupId) return <Redirect to={`/request/${reffererGroupId}`} />
  return (
    <GroupNameSettingPresenter
      rhfMethods={rhfMethods}
      control={rhfMethods.control}
      name="invitationKey"
      errors={rhfMethods.formState.errors}
      onSubmit={onSubmit}
      isSuccess={isSuccess}
      isError={isError}
      isLoading={isPending}
      error={
        error instanceof FirebaseError || error instanceof Error
          ? error
          : undefined
      }
    ></GroupNameSettingPresenter>
  );
};
