
import { z } from "zod"
import { FullyRegisteredUserType, } from "./userType"
import { documentReferenceSchema, firestoreTimestampSchema } from "./firestoreSchema"
import { DocumentReference, Timestamp } from "firebase/firestore"

export const groupNameFormSchema = z.object({
    name: z.string().min(1, "room name must contain at least 1 character"),
})
export type GroupNameFormType = z.infer<typeof groupNameFormSchema>
export type UnstoredGroupType = z.infer<typeof groupNameFormSchema> & {
    owner: DocumentReference<FullyRegisteredUserType>
    member: DocumentReference<FullyRegisteredUserType>[]
    pendingMember: DocumentReference<FullyRegisteredUserType>[]
}

export const unstoredGroupSchema: z.ZodType<UnstoredGroupType> = groupNameFormSchema.extend({
    owner: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>()),
    member: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>().array()),
    pendingMember: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>().array()),
})

export type GroupWithIdType = z.infer<typeof unstoredGroupSchema> & {
    id: string,
    createdAt: Timestamp,
    updatedAt: Timestamp
}

export type StoredGroupType = GroupWithIdType

export const groupWithIdSchema: z.ZodType<GroupWithIdType> = groupNameFormSchema.extend({
    id: z.string().min(1),
    owner: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>()),
    member: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>().array()),
    pendingMember: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>().array()),
    createdAt: firestoreTimestampSchema,
    updatedAt: firestoreTimestampSchema
})

export const storedGroupSchema = groupWithIdSchema

export const requestApprovalFormSchema = z.object({
    uid: z.string().min(1)
})

export const requestToJoinGroupFormSchema = z.object({
    groupId: z.string().min(1)
})

export type RequestApprovalFormType = z.infer<typeof requestApprovalFormSchema>

export type GroupType = GroupWithIdType
