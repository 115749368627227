import { collection, getDocs } from "firebase/firestore"
import { GroupInvitationType, storedGroupInvitationSchema } from "../../../../../../sharedEntities/groupInvitationType"
import { firestoreConverter } from "../../../../../util/firestoreConverter"
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization"
import { UseValidGroupId } from "../../hook/UseValidGroupId"
import { useQuery } from "@tanstack/react-query"
import { useLocation } from "react-router"
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId"

export function UseExistInvitationLink() {
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: db } = UseFirestoreInitialization()

    const baseUrl = (import.meta.env.MODE === "development" || window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") ? "http://localhost:5173/emailAction?mode=verifyGroupInvitation" : 'https://app.fridge-co.com/emailAction?mode=verifyGroupInvitation'

    async function getExistInvitationLink() {
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')

        const groupInvitationCollectionRef = collection(db, `version/v1/groups/`, currentGroupId, `groupInvitation`).withConverter(firestoreConverter(storedGroupInvitationSchema))
        const groupInvitationDocs = await getDocs(groupInvitationCollectionRef)
        if (groupInvitationDocs.empty) return null
        const existInvitationDoc = groupInvitationDocs.docs.map((item) => item)[0]
        console.log({ existInvitationDoc })
        if (!existInvitationDoc.exists) return null
        const linkUrl = `${baseUrl}&groupId=${currentGroupId}&oobCode=${existInvitationDoc.id}`
        return linkUrl

    }

    return useQuery({ queryKey: ['existInvitationLink', currentGroupId], queryFn: getExistInvitationLink, enabled: !!db && !!currentGroupId })
}