import { useMutation } from "@tanstack/react-query";

import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";
import { collection, query, where, getDocs, addDoc, doc, updateDoc, arrayUnion, serverTimestamp } from "firebase/firestore";

import { ReactionType, storedReactionSchema } from "../../../../../../sharedEntities/reactionType";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { UseCurrentPostId } from "../../../../../globalHook/useCurrentPostId";
import { UseUserInfo } from "../../../../../globalHook/UseUserInfo";
import { fullyRegisteredUserSchema } from "../../../../../../sharedEntities";

export function UseReactionEmojiAdd() {

    const { data: currentUser } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: currentPostId } = UseCurrentPostId()


    async function addReactionEmoji(formData: { reactionEmoji: string }) {
        console.log("on mutate");
        if (!currentUser) throw new Error("currentUser error");
        if (!db) throw new Error("db error");
        if (!currentGroupId) throw new Error("groupId error");
        if (!currentPostId) throw new Error("postId error");

        const reactionCollectionRef = collection(
            db,
            "version/v1/groups",
            currentGroupId,
            "posts",
            currentPostId,
            "reactions"
        ).withConverter(firestoreConverter(storedReactionSchema));

        const sameReactionQuery = query(
            reactionCollectionRef,
            where("reactionEmoji", "==", formData.reactionEmoji)
        );
        const queryResult = await getDocs(sameReactionQuery);

        if (queryResult.empty) {
            return await addDoc(reactionCollectionRef, {
                reactionEmoji: formData.reactionEmoji,
                members: [doc(db, "version/v1/users", currentUser.uid)],
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            });
        }

        const doesDocExist = queryResult.docs[0].exists();
        if (doesDocExist === false) {
            return await addDoc(reactionCollectionRef, {
                reactionEmoji: formData.reactionEmoji,
                members: [doc(db, "version/v1/users", currentUser.uid)],
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            });
        }

        const reactionDocData = queryResult.docs[0].data();
        const reactionDocRef = queryResult.docs[0].ref;
        const memberIds = reactionDocData.members.map((item) => item.id);
        const doesCurrentUserExistInMembers = memberIds.includes(currentUser.uid);
        const currentUserDocRef = doc(
            db,
            "version/v1/users",
            currentUser.uid
        ).withConverter(firestoreConverter(fullyRegisteredUserSchema));

        if (doesCurrentUserExistInMembers) return true;
        return await updateDoc(reactionDocRef, {
            members: arrayUnion(currentUserDocRef),
        });
    }
    return useMutation({
        mutationFn: addReactionEmoji
    });
}