import { collection, collectionGroup, getDocs, query, serverTimestamp, where } from "firebase/firestore"
import { UseUserInfo } from "../../../../../globalHook/UseUserInfo"
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization"
import { useMutation, useQuery } from "@tanstack/react-query"
import { storedGroupInvitationSchema } from "../../../../../../sharedEntities/groupInvitationType"
import { firestoreConverter } from "../../../../../util/firestoreConverter"

type Props = {}
export const UseVerifyInvitationKey = () => {
    const { data: db } = UseFirestoreInitialization()

    const verifyInvitationKey = async ({ invitationKey }: { invitationKey: string }) => {
        if (!db) throw new Error("db error")
        const groupInvitationsQuery = query(
            collectionGroup(db, "groupInvitation"),
            where("shortCode", "==", invitationKey),
        ).withConverter(firestoreConverter(storedGroupInvitationSchema))
        const querySnapshot = await getDocs(groupInvitationsQuery)
        if (querySnapshot.empty) throw new Error("invalid invitation key")
        const available = querySnapshot.docs[0].data().available
        if (!available) throw new Error("invalid invitation key")
        const groupId = querySnapshot.docs[0].ref.parent.parent?.id
        console.log({ available, groupId })
        return !querySnapshot.empty
    }

    return useMutation({ mutationFn: verifyInvitationKey })
}