import { useMutation } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { confirmPasswordReset, getAuth } from "firebase/auth";

export function UseResetPassword(actionCode: string | null) {
    async function resetPassword(formData: { password: string }) {
        if (!actionCode) throw new Error("need actioncode")

        const auth = getAuth()
        if (!auth) throw new Error("auth error")

        try {
            await confirmPasswordReset(auth, actionCode, formData.password)
            return true
        } catch (error) {
            if (error instanceof Error || error instanceof FirebaseError) throw error
            throw new Error("unknown error")
        }

    }
    return useMutation({ mutationFn: resetPassword })
}