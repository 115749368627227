import { IonSpinner } from "@ionic/react";
import { GroupFeedPresenter } from "./GroupFeedPresenter";
import { UseValidGroupId } from "./hook/UseValidGroupId";
import { UseCurrentGroupId } from "../../../globalHook/useCurrentGroupId";
import { useRef } from "react";

export function GroupFeedContainer() {
  const chatModalRef = useRef<HTMLIonModalElement>(null);
  const journalModalRef = useRef<HTMLIonModalElement>(null);

  return (
    <GroupFeedPresenter
      chatModalRef={chatModalRef}
      journalModalRef={journalModalRef}
    />
  );
}
