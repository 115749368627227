import { UseCurrentGroupId } from "../../../../../../../globalHook/useCurrentGroupId";
import { CommentReactionsPresenter } from "./CommentReactionsPresenter";
import { UseCommentReactionAdd } from "./useCommentReactionAdd";
import { UseCommentReactionRemove } from "./useCommentReactionRemove";
import { UseCommentReactions } from "./useCommentReactions";

export type Props = { commentId: string };
export function CommentReactionsContainer({ commentId }: Props) {
  const { data: reactionsData, isSuccess } = UseCommentReactions(commentId);
  const { mutate: removeReaction } = UseCommentReactionRemove();
  const { mutate: addReaction } = UseCommentReactionAdd();

  function onReactionEmojiClick(
    reactionId: string,
    actionType: "remove" | "add"
  ) {
    if (actionType === "remove") removeReaction({ commentId, reactionId });
    if (actionType === "add") addReaction({ commentId, reactionId });
  }

  if (isSuccess)
    return (
      <CommentReactionsPresenter
        reactionsData={reactionsData}
        onReactionEmojiClick={onReactionEmojiClick}
      />
    );
  return null;
}
