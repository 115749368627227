import "swiper/css";
import {
  IonButton,
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonNav, IonPage, IonRouterOutlet, IonTitle, IonToolbar,
} from "@ionic/react";
import { UserPattern } from "../../../globalHook/UseUserType";
import { VerifyEmailContainer } from "./components/VerifyEmail/VerifyEmailContainer";
import { register } from "swiper/element/bundle"
import { useEffect, useRef } from "react";
import Swiper from "swiper";
import { SwiperRef } from "swiper/react";
import { Link, Redirect, Route } from "react-router-dom";
import { SetDisplayNameContainer } from "./components/SetDisplayNameSlide/SetDisplayNameContainer";
import { GroupNameSettingContainer } from "./components/GroupNameSettingSlide/GroupNameSettingContainer";
import { AnimatePresence, motion } from "framer-motion";



type Props = {
  userPattern: UserPattern
};
const variants = {
  hidden: { opacity: 0, x: 100 },
  show: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 }
};
export function WelcomePresenter({
  userPattern
}: Props) {
  return (

    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Welcome to Fridge!</IonTitle>
        </IonToolbar>
      </IonHeader>
      {FormComponent(userPattern)}
    </IonPage>



  )
}

function FormComponent(userPattern: UserPattern) {
  switch (userPattern) {
    case "unverifiedEmailUser":
      return (
        <VerifyEmailContainer ></VerifyEmailContainer>
      )
    case "emailVerifiedButDisplayNameUnsetUser":
      return (
        <SetDisplayNameContainer></SetDisplayNameContainer>
      )
    case "noGroupUser":
      return (
        <GroupNameSettingContainer></GroupNameSettingContainer>
      )
    default:
      return <Redirect from="/" exact to={"/home"}></Redirect>
  }

}