import { IonList, IonItem, IonLabel } from "@ionic/react";
import { CommentItemContainer } from "./ui/commentItem/CommentItemContainer";
import { CommentType } from "../../../../../../sharedEntities/commentType";
import { CommentItemType } from "./ui/commentItem/type";

type Props = {
  commentItemsData: CommentItemType[];
};

export function CommentListPresenter({ commentItemsData }: Props) {
  return (
    <IonList lines="none">
      {commentItemsData.map((item, index) => (
        <CommentItemContainer key={index} commentItemData={item} />
      ))}
    </IonList>
  );
}
