import { Unsubscribe, collection, doc, getDoc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { UseCurrentGroupId } from "../../../../../../../globalHook/useCurrentGroupId";
import { UseFirestoreInitialization } from "../../../../../../../globalHook/UseFirestoreInitialization";
import { storedCommentSchema } from "../../../../../../../../sharedEntities/commentType";
import { firestoreConverter } from "../../../../../../../util/firestoreConverter";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { UseUserInfo } from "../../../../../../../globalHook/UseUserInfo";

export function UseRealtimeCommentInfo(postId: string) {
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: userInfo } = UseUserInfo()
    const queryClient = useQueryClient()
    const queryKey = ["UseRealtimeCommentInfo", postId]


    const getCommentInfo = async (): Promise<{
        count: number,
        topThreeUsers: { uid: string, displayName: string }[]
        unsubscribe: Unsubscribe | undefined
    }> => {

        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')
        if (!userInfo) throw new Error('currentUser error')

        let firstRun = true

        const commentCollectionRef = collection(
            db,
            "version/v1/groups",
            currentGroupId,
            "posts",
            postId,
            "comments"
        ).withConverter(firestoreConverter(storedCommentSchema));

        const commentQuery = query(
            commentCollectionRef,
            orderBy("createdAt", "desc"),
            where("visible", "array-contains-any", [
                doc(db, "version/v1/users/", userInfo.uid),
                "everyoneInGroups",
            ])
        );


        const data = new Promise<{
            count: number,
            topThreeUsers: { uid: string, displayName: string }[]
            unsubscribe: Unsubscribe
        }>((resolve, reject) => {
            const unsbscribe = onSnapshot(commentQuery, async (snapshot) => {
                const commentCount = snapshot.size;

                const commentAuthorList = await Promise.all(
                    snapshot.docs.map(async (document) => {
                        const authorDocsnapshot = await getDoc(document.data().author);
                        if (!authorDocsnapshot.exists()) return { empty: true };

                        return {
                            empty: false,
                            uid: authorDocsnapshot.id,
                            displayName: authorDocsnapshot.data().displayName,
                        };
                    }))

                const filteredCommentAuthorList = commentAuthorList
                    .filter((item): item is { empty: false; uid: string; displayName: string } => !item.empty)
                    .map((item) => {
                        return { uid: item.uid, displayName: item.displayName };
                    });




                const uniqueCommentAuthorList = filteredCommentAuthorList.filter((item, index, self) => {
                    return self.findIndex((t) => t.uid === item.uid) === index;
                });

                const commentTopThreeUsers = uniqueCommentAuthorList.slice(0, 3)
                if (firstRun) {
                    resolve({
                        count: commentCount,
                        topThreeUsers: commentTopThreeUsers,
                        unsubscribe: unsbscribe
                    })
                    firstRun = false;
                } else {
                    queryClient.setQueryData(queryKey, {
                        count: commentCount,
                        topThreeUsers: commentTopThreeUsers,
                        unsubscribe: unsbscribe
                    })
                }



            }
                , (error) => {
                    if (firstRun) {
                        reject(error)
                        firstRun = false;
                    } else {
                        queryClient.invalidateQueries({ queryKey })
                    }
                }

            )

        })


        const previousData = queryClient.getQueryData<{
            count: number | undefined,
            topThreeUsers: { uid: string, displayName: string }[] | undefined
            unsubscribe: Unsubscribe | undefined
        }>(queryKey)

        previousData?.unsubscribe && previousData.unsubscribe()

        return data
    }

    return useQuery({
        queryKey: queryKey,
        queryFn: getCommentInfo,
        enabled: !!db && !!currentGroupId && !!userInfo,
        gcTime: Infinity,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    })
}