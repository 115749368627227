import { Redirect, RouteComponentProps } from "react-router";
import { InviteContainer } from "../../components/feature/Invite/InviteContainer";
import { UseVerifyInvitation } from "../../components/feature/Invite/UseVerifyInvitation";
import { UseSetReffererGroupId } from "../../globalHook/UseSetReffererGroupId";
import { RequestContainer } from "../../components/feature/Request/RequestContainer";
interface RequestPageProps extends RouteComponentProps<{
    groupId: string;
}> {

}

export function Request({ match }: RequestPageProps) {
    const { groupId } = match.params
    return <RequestContainer groupId={groupId} />
}
