import { SubmitHandler, useForm } from "react-hook-form";
import { CardWithForm } from "../../../../../Home/ui/cardWithForm.tsx/CardWithForm";
import { UseGroupInvitationVerification } from "../../hook/UseGroupInvitationVerification";
import { RequestToJoinTheGroupPresenter } from "./RequestToJoinTheGroupPresenter";
import { UseJoinGroupRequest } from "./UseJoinGroupRequest";
import { FormType, zodSchema } from "./type";
import { zodResolver } from "@hookform/resolvers/zod";
import { FirebaseError } from "firebase/app";

type Props = {
  groupId: string;
  invitationCode: string;
};

export function RequestToJoinTheGroupContainer({
  groupId,
  invitationCode,
}: Props) {
  const rhfMethods = useForm<FormType>({
    resolver: zodResolver(zodSchema),
  });
  const { mutate, isSuccess, isPending, isError, error } =
    UseJoinGroupRequest();

  const onSubmitFunction: SubmitHandler<FormType> = (data, e) => {
    e?.preventDefault();
    mutate(rhfMethods.watch());
  };

  return (
    <RequestToJoinTheGroupPresenter
      rhfMethods={rhfMethods}
      onSubmitFunction={onSubmitFunction}
      groupId={groupId}
      invitationCode={invitationCode}
      isSuccess={isSuccess}
      isPending={isPending}
      isError={isError}
      error={
        error instanceof FirebaseError || error instanceof Error
          ? error
          : undefined
      }
    ></RequestToJoinTheGroupPresenter>
  );
}
