import { DocumentReference } from "firebase/firestore"
import { FullyRegisteredUserType } from "./userType"
import { z } from "zod"
import { documentReferenceSchema } from "./firestoreSchema"


export const postSchema = z.object({
    title: z.string().min(1),
    subtitle: z.string().min(1),
    message: z.string().min(1),
    messageToIds: z.array(z.object({ checked: z.boolean(), id: z.string().min(1) })).default([]),
    messageType: z.union([z.literal("✉️"), z.literal("😘"), z.literal("📓")])
})
export const unstoredBasePostSchema = postSchema.extend({})

export type UnstoredPostType =
    Omit<z.infer<typeof unstoredBasePostSchema>, | "messageToIds">
    & {
        author: DocumentReference<FullyRegisteredUserType>
        visible: DocumentReference<FullyRegisteredUserType>[] | ["everyoneInGroups"]
        messageTo: DocumentReference<FullyRegisteredUserType>[]
    }

export const unstoredPostSchema: z.ZodType<UnstoredPostType> = unstoredBasePostSchema.extend({
    author: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>()),
    visible: z.lazy(() => z.union([documentReferenceSchema<FullyRegisteredUserType>().array(), z.tuple([z.literal("everyoneInGroups")])])),
    messageTo: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>().array())
})

export const storedBasePostSchema = postSchema.extend({
    createdAt: z.date(),
    updatedAt: z.date()
})

export type StoredPostType =
    Omit<z.infer<typeof storedBasePostSchema>, "messageToIds">
    & {
        author: DocumentReference<FullyRegisteredUserType>
        visible: DocumentReference<FullyRegisteredUserType>[] | ["everyoneInGroups"]
        messageTo: DocumentReference<FullyRegisteredUserType>[]
    }

export const storedPostSchema: z.ZodType<StoredPostType> = storedBasePostSchema.extend({
    author: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>()),
    visible: z.lazy(() => z.union([documentReferenceSchema<FullyRegisteredUserType>().array(), z.tuple([z.literal("everyoneInGroups")])])),
    messageTo: z.lazy(() => documentReferenceSchema<FullyRegisteredUserType>().array())

})


export type PostType = StoredPostType  