import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { CardWithForm } from "../Home/ui/cardWithForm.tsx/CardWithForm";
import { CardWithoutForm } from "../Home/ui/cardWithoutForm.tsx/CardWithoutForm";

type Props = Omit<
  React.ComponentProps<typeof CardWithForm>,
  "title" | "subtitle" | "content" | "buttonLabel" | "buttonProps" | "inputs"
>;
export function ForgotPasswordPresenter({ ...props }: Props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Fridge</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <CardWithForm
          {...props}
          title={"Forgot password?"}
          subtitle={""}
          content={"Enter your email address and we'll send you a link to reset your password."}
          inputs={[
            {
              inputLabel: "email",
              inputRegisterName: "email",
              inputProps: {
                type: "email",
                autocomplete: "email",
              },
            },
          ]}
          buttonLabel="Send reset email"
          buttonProps={{}}
        ></CardWithForm>
      </IonContent>

    </IonPage>

  );
}
