import { RouteComponentProps, useLocation } from "react-router";
import { GroupFeedContainer } from "../../../components/feature/GroupFeed/GroupFeedContainer";
import { UseSetCurrentGroupId } from "../../../globalHook/useSetCurrentGroupId";
import { useEffect } from "react";
import { UseFirestoreInitialization } from "../../../globalHook/UseFirestoreInitialization";
import { UseUserInfo } from "../../../globalHook/UseUserInfo";

interface FeedPageProps
  extends RouteComponentProps<{
    groupId: string;
  }> { }

export function Feed({ match }: FeedPageProps) {
  const { data: currentUser } = UseUserInfo();
  const { data: db } = UseFirestoreInitialization();
  const { mutate: setCurrentGroupId } = UseSetCurrentGroupId();
  useEffect(() => {
    if (match && currentUser && db) setCurrentGroupId(match.params.groupId);
    console.log({ match })
  }, [currentUser, db, match]);
  return <GroupFeedContainer></GroupFeedContainer>;
}
