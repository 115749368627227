import { useQuery } from "@tanstack/react-query";
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { doc, getDoc } from "firebase/firestore";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { storedGroupSchema, } from "../../../../../../sharedEntities";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

export function UseCurrentGroupName() {
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: db } = UseFirestoreInitialization()


    async function getCurrentGroupName() {

        if (!db) throw new Error("db error")
        if (!currentGroupId) throw new Error("currentGroupId error")
        const groupDocRef = doc(db, "version/v1/groups", currentGroupId).withConverter(firestoreConverter(storedGroupSchema))
        const groupDoc = await getDoc(groupDocRef)
        if (!groupDoc.exists()) throw new Error("group doc does not exists")
        const name = groupDoc.data().name
        if (!name) throw new Error('name error')
        return name
    }

    return useQuery({ queryKey: ["currentGroupName", currentGroupId], queryFn: getCurrentGroupName, enabled: !!db && !!currentGroupId })
}