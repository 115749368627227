import { SubmitHandler, useForm } from "react-hook-form";
import { AddGroupMemberPresenter } from "./AddGroupMemberPresenter";
import { UseCreateLink } from "./UseCreateLink";
import { UseCurrentGroupName } from "./UseCurrentGroupName";
import { UseExistInvitationLink } from "./UseExistInvitationLink";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { UseLinkCopyToClipboard } from "./UseLinkCopyToClipboard";
import { UseValidGroupId } from "../../hook/UseValidGroupId";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

export function AddGroupMemberContainer() {
  const { data: currentGroupId } = UseCurrentGroupId();
  const { data: currentGroupName, isSuccess } = UseCurrentGroupName();
  const {
    data: groupInvitationLinkUrl,
    mutateAsync: createLink,
    isSuccess: createLinkIsSuccess,
  } = UseCreateLink();
  const {
    data: existInvitationLinkUrl,
    isSuccess: existInvitationLinkUrlIsSuccess,
    isLoading: existInvitationLinkUrlIsLoading,
  } = UseExistInvitationLink();

  const onClickFunction: React.MouseEventHandler = async (e) => {
    e?.preventDefault();
    const createLinkResult = await createLink();
  };
  return (
    <AddGroupMemberPresenter
      currentGroupName={isSuccess ? currentGroupName : ""}
      groupInvitationLinkUrl={
        existInvitationLinkUrl
          ? existInvitationLinkUrl
          : createLinkIsSuccess
          ? groupInvitationLinkUrl
          : null
      }
      createLinkIsSuccess={createLinkIsSuccess}
      onClickFunction={onClickFunction}
    />
  );
}
