import { doc, getDoc } from "firebase/firestore";
import { UseFirestoreInitialization } from "../../../globalHook/UseFirestoreInitialization";
import { GroupWithIdType, storedGroupSchema } from "../../../../sharedEntities";
import { firestoreConverter } from "../../../util/firestoreConverter";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";


export function useGroupInfo<T = GroupWithIdType>(groupId: string, select?: ((data: GroupWithIdType) => T)) {
    const { data: db } = UseFirestoreInitialization()

    async function getGroupInfo(): Promise<GroupWithIdType> {
        if (!db) throw new Error("db error")
        const groupDocRef = doc(db, "version/v1/groups", groupId).withConverter(firestoreConverter(storedGroupSchema))
        const groupDoc = await getDoc(groupDocRef)
        if (!groupDoc.exists()) throw new Error("group does not exist")
        console.log(groupDoc.data())
        return groupDoc.data()
    }

    return useQuery({
        queryKey: ["groupInfo", groupId]
        , queryFn: getGroupInfo
        , enabled: !!db && !!groupId
        , select: select
    })
}