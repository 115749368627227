import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuth, reload, updateProfile } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { displayNameFormSchema, emailVerifiedButDisplayNameUnsetUserSchema, noGroupUserSchema } from "../../../../../../sharedEntities";
import { z } from "zod";

export const UseSetDisplayName = () => {
    const queryClient = useQueryClient()
    type FormType = z.infer<typeof displayNameFormSchema>

    const setDisplayName = async (formData: FormType) => {
        const auth = getAuth();
        if (!auth) throw new Error("auth error")
        const currentUser = auth.currentUser
        if (!currentUser) throw new Error("current user error")
        const db = getFirestore();
        if (!db) throw new Error("firestore error")

        const userDocumentRef = doc(db, "version/v1/users", currentUser.uid).withConverter(firestoreConverter(noGroupUserSchema))
        const userDocument = await getDoc(userDocumentRef)
        if (!userDocument.exists()) throw new Error("user document does not exist")

        try {
            await updateDoc(userDocumentRef, { displayName: formData.displayName })
            await updateProfile(auth.currentUser, { displayName: formData.displayName })
            if (auth.currentUser) reload(auth.currentUser)
        } catch (error) {
            throw error
        }

    }
    return useMutation({
        mutationFn: setDisplayName, onSuccess(data, variables, context) {
            queryClient.invalidateQueries({ queryKey: ["userType"] })
        },
    })
}