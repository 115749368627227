import { IonButton, IonCard, IonCardContent, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useGroupInfo } from "./useGroupInfo";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { requestToJoinGroupFormSchema } from "../../../../sharedEntities";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseSendRequest } from "./UseSendRequest";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
    groupId: string
};
export function RequestContainer({ groupId }: Props) {
    const queryClient = useQueryClient()
    const { data: groupName, isPending, isSuccess, isError, error } = useGroupInfo(groupId, (data) => data.name)
    const rhfMethods = useForm<z.infer<typeof requestToJoinGroupFormSchema>>({
        resolver: zodResolver(requestToJoinGroupFormSchema)
    })
    const { mutate: sendRequest } = UseSendRequest()
    const onSubmit: SubmitHandler<z.infer<typeof requestToJoinGroupFormSchema>> = (data, e) => {
        e?.preventDefault()
        sendRequest(data.groupId, {
            onSuccess(data, variables, context) {
                queryClient.invalidateQueries({ queryKey: ["userType"] })
            },
        })
    }

    if (isPending) return <div>loading...</div>
    if (isError) return <div>{error.message}</div>

    return (

        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Fridge</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonText>
                            <h2>Join the group below.</h2>
                        </IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonText>
                            <h2>{groupName}</h2>
                        </IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <form onSubmit={rhfMethods.handleSubmit(onSubmit)}>
                            <input hidden {...rhfMethods.register("groupId")} value={groupId} />
                            <IonButton type="submit" expand="block">Send a request</IonButton>
                        </form>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>)
}