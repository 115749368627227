import { Unsubscribe, collection, doc, getDoc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { CommentItemType } from "./ui/commentItem/type";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { CommentType, storedCommentSchema } from "../../../../../../sharedEntities/commentType";
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseCurrentPostId } from "../../../../../globalHook/useCurrentPostId";
import { UseUserInfo } from "../../../../../globalHook/UseUserInfo";

export function UseRealtimeCommentList() {

    const { data: currentUser } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: currentPostId } = UseCurrentPostId()
    const queryKey = ["useCommentList", currentGroupId, currentPostId]

    let firstRun = true;

    const queryClient = useQueryClient()

    async function getCommentList(): Promise<{
        comments: CommentItemType[],
        unsubscribe: Unsubscribe
    }> {
        if (!currentUser) throw new Error('currentUser error')
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')
        if (!currentPostId) throw new Error('currentPostId error')

        const commentsCollectionRef = collection(db, "version/v1/groups", currentGroupId, "posts", currentPostId, "comments").withConverter(firestoreConverter(storedCommentSchema))
        const visibleCommentsQuery = query(
            commentsCollectionRef,
            where("visible", "array-contains-any", [doc(db, "version/v1/users/", currentUser.uid), "everyoneInGroups",])
            , orderBy("createdAt", "desc"),)


        const data = new Promise<{
            comments: CommentItemType[],
            unsubscribe: Unsubscribe
        }>(async (resolve, reject) => {
            const unsubscribe = onSnapshot(visibleCommentsQuery, async (querySnapshot) => {

                const commentsData = await Promise.all(
                    querySnapshot.docs.map(async (doc) => {
                        const authorDocRef = doc.data().author;
                        const authorDoc = await getDoc(authorDocRef);
                        if (!authorDoc.exists()) throw new Error("author error");

                        const fromDisplayName = authorDoc.data().displayName;
                        if (!fromDisplayName) throw new Error("display name is not set");
                        const fromUid = authorDoc.id;

                        const message = doc.data().message;
                        const createdAtDate = doc.data().createdAt;
                        const createdAtDateFormatString =
                            createdAtDate.toLocaleDateString();
                        const createdAtTimeFormatString =
                            createdAtDate.toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                            });

                        const commentItem: CommentItemType = {
                            commentId: doc.id,
                            fromUid: fromUid,
                            fromDisplayName: fromDisplayName,
                            message: message,
                            createdAtDateFormatString: createdAtDateFormatString,
                            createdAtTimeFormatString: createdAtTimeFormatString
                        }

                        return commentItem
                    })

                )
                const reversedCommentsData = commentsData.slice().reverse()

                if (firstRun) {

                    resolve({ comments: reversedCommentsData, unsubscribe })
                    firstRun = false;
                } else {
                    queryClient.setQueryData(queryKey, {
                        comments: reversedCommentsData,
                        unsubscribe: unsubscribe
                    })
                }


            }, (error) => {
                if (firstRun) {
                    reject(error)
                } else {
                    queryClient.invalidateQueries({ queryKey })
                }
            })
        })

        const previousData = queryClient.getQueryData<{
            comments: CommentItemType[],
            unsubscribe: Unsubscribe | undefined
        }>(queryKey)

        previousData?.unsubscribe && previousData.unsubscribe()

        return data

    }

    return useQuery({
        queryKey: ["useCommentList", currentGroupId, currentPostId],
        queryFn: getCommentList,
        enabled: !!currentUser && !!db && !!currentGroupId,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: Infinity,
    })

}