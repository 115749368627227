import { SubmitHandler, useForm } from "react-hook-form";
import { PostCommentReactionPresenter } from "./PostCommentReactionPresenter";
import { UsePostCommentReaction } from "./usePostCommentReaction";
import { FormType, zodSchema } from "./type";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRef } from "react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
export function PostCommentReactionContainer({
  commentId,
}: {
  commentId: string;
}) {
  const modalRef = useRef<HTMLIonModalElement>(null);

  const rhfMethods = useForm<FormType>({
    resolver: zodResolver(zodSchema),
  });
  const { mutate: postCommentReaction } = UsePostCommentReaction({ commentId });

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === "emojiSelect") {
      rhfMethods.setValue("reactionEmoji", ev.detail.data);
      rhfMethods.handleSubmit(onSubmit)();
    }
  }
  const openModal = () => {
    modalRef.current?.present();
  };
  const closeModal = () => {
    modalRef.current?.dismiss();
  };

  function onEmojiSelect(data: any, event: any) {
    modalRef.current?.dismiss(data.native, "emojiSelect");
  }

  const onSubmit: SubmitHandler<FormType> = (data, e) => {
    console.log("onSubmitFunction");
    e?.preventDefault();
    postCommentReaction(data);
  };

  return (
    <PostCommentReactionPresenter
      modalRef={modalRef}
      rhfMethods={rhfMethods}
      openModal={openModal}
      closeModal={closeModal}
      onWillDismiss={onWillDismiss}
      onEmojiSelect={onEmojiSelect}
    />
  );
}
