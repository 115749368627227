import { IonSkeletonText, IonSpinner } from "@ionic/react";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";
import { UseValidGroupId } from "../../hook/UseValidGroupId";
import { ToolBarPresenter } from "./ToolBarPresenter";
import { UseCurrentGroupName } from "./UseCurrentGroupName";
import { UseUnapprovalMembersInfo } from "./UseUnapprovalMembersInfo";

export function ToolBarContainer() {
  const { data: currentGroupId } = UseCurrentGroupId();
  const { data: currentGroupName, isSuccess: fetchCurrentGroupNameSuccess } =
    UseCurrentGroupName();
  const {
    data: hasUnapprovedMemberInfo,
    isSuccess: hasUnapprovedMemberIsSuccess,
  } = UseUnapprovalMembersInfo();

  if (!currentGroupId) return <IonSkeletonText />;
  return (
    <ToolBarPresenter
      currentGroupName={fetchCurrentGroupNameSuccess ? currentGroupName : ""}
      hasUnapprovedMember={
        hasUnapprovedMemberIsSuccess
          ? hasUnapprovedMemberInfo.hasUnapprovedMember
          : false
      }
      unapprovedMembersCount={
        hasUnapprovedMemberIsSuccess
          ? hasUnapprovedMemberInfo.unapprovedMembersCount
          : 0
      }
      currentGroupId={currentGroupId}
    ></ToolBarPresenter>
  );
}
