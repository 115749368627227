import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonMenuButton,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronDown, chevronDownOutline, personOutline } from "ionicons/icons";
import { Link } from "react-router-dom";

type Props = {
  currentGroupName: string;
  hasUnapprovedMember: boolean;
  unapprovedMembersCount: number;
  currentGroupId: string;
};
export function ToolBarPresenter({
  currentGroupName,
  hasUnapprovedMember,
  unapprovedMembersCount,
  currentGroupId,
}: Props) {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonButton routerLink={`/g/${currentGroupId}/manageMembers`}>
          {unapprovedMembersCount > 0 ? (
            <IonBadge color={"danger"}>{unapprovedMembersCount}</IonBadge>
          ) : null}
          <IonIcon
            icon={personOutline}
            color={hasUnapprovedMember ? "danger" : ""}
          ></IonIcon>
        </IonButton>
      </IonButtons>
      <IonTitle class="ion-justify-content-center">
        {currentGroupName}
        <IonIcon icon={chevronDownOutline} id="popover-trigger"></IonIcon>
        <IonPopover trigger="popover-trigger">
          <IonContent>
            <IonList>
              <IonItem>current</IonItem>
            </IonList>
          </IonContent>
        </IonPopover>
      </IonTitle>
    </IonToolbar>
  );
}
