import { UseUserType } from "../../../globalHook/UseUserType";
import { UseSignInCardState } from "../SignIn/feature/signUpOrSignIn/hook/useSignInCardState";
import { WelcomePresenter } from "./WelcomePresenter";

export function WelcomeContainer() {
  const { data: userPattern } = UseUserType((userInfo) => userInfo.userPattern)
  console.log(userPattern)
  if (!userPattern) throw new Error("userPattern error")
  return (
    <WelcomePresenter
      userPattern={userPattern}
    />
  );
}
