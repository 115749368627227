import { getAuth } from "firebase/auth"
import { UseFirestoreInitialization } from "../../../../../../globalHook/UseFirestoreInitialization"
import { useQuery } from "@tanstack/react-query"
import { collection, doc, getDoc, query as firestoreQuery, where, Timestamp, getDocs, documentId, serverTimestamp } from "firebase/firestore"
import { firestoreConverter } from "../../../../../../util/firestoreConverter"
import { GroupType } from "../../../../../../../sharedEntities"
import { GroupInvitationType, storedGroupInvitationSchema } from "../../../../../../../sharedEntities/groupInvitationType"
import { FirebaseError } from "firebase/app"
import { UseUserInfo } from "../../../../../../globalHook/UseUserInfo"

export function UseGroupInvitationVerification(query: URLSearchParams) {
    const { data: userInfo } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const groupId = query.get("groupId")
    const invitationCode = query.get("oobCode")
    console.log({ groupId, invitationCode })
    async function verifyGroupInvitation(): Promise<({ result: true, validGId: string, validInvitationCode: string } | { result: false })> {
        const auth = getAuth()
        if (!auth) throw new Error("auth error")
        if (!db) throw new Error("db error")
        if (!groupId) throw new Error("groupId empty")
        if (!invitationCode) throw new Error('invitationCode error')
        if (!userInfo) throw new Error("userInfo error")



        const groupInvitationDocRef = doc(db, `version/v1/groups/`, groupId, `groupInvitation`, invitationCode).withConverter(firestoreConverter(storedGroupInvitationSchema))
        try {
            const groupInvitationDoc = await getDoc(groupInvitationDocRef)
            if (!groupInvitationDoc.exists()) throw new Error("this invitation code does not exist")
            console.log("invitation code exists")
            const isValid = groupInvitationDoc.data().available === true

            if (!isValid) throw new Error("code is not valid")
            return { result: true, validGId: groupId, validInvitationCode: invitationCode }

        } catch (error) {
            if (error instanceof FirebaseError || error instanceof Error) {
                if (error instanceof FirebaseError && error.code === "permission-denied") throw new Error("This code is expired")
                throw error
            }

            throw new Error("unkown error")
        }




    }

    return useQuery({ queryKey: ["groupInvitationVerification"], queryFn: verifyGroupInvitation, enabled: !!db && !!userInfo, refetchOnWindowFocus: false })
}