import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonGrid,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { FeedItemPresenter } from "./ui/FeedItemPresenter";
import {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  forwardRef,
  useState,
} from "react";
import { FeedItemData } from "./type";
import { ToolBarContainer } from "../../ui/toolBar/ToolBarContainer";
import "./FeedPresenter.css";
import { FeedItemContainer } from "./ui/FeedItemContainer";
import { Components, Virtuoso } from "react-virtuoso";
import React from "react";
import { PostJournalContainer } from "../postJournal/PostJournalContainer";
import { PostContainer } from "../post/PostContainer";

type Props = {
  timelineItems: FeedItemData[];
  hasMore: boolean;
  onStartReached: () => Promise<any>;
  ionContentRef: React.RefObject<HTMLIonContentElement>;
  pageRef: React.RefObject<any>;
  journalModalRef: React.RefObject<any>;
};
export const FeedPresenter = ({
  timelineItems,
  hasMore,
  onStartReached,
  ionContentRef,
  pageRef,
  journalModalRef,
}: Props) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [firstItemIndex, setFirstItemIndex] = useState(100000000000);
  const [isLoading, setIsLoading] = useState(false);

  const prependItems = useCallback(async () => {
    if (hasMore) {
      const dataToPrepend = 20;
      const nextFirstIndex = firstItemIndex - dataToPrepend;
      setIsLoading(true);
      setTimeout(async () => {
        const test = await onStartReached();
        if (test.status === "success") {
          setFirstItemIndex(() => nextFirstIndex);
          setIsLoading(false);
        }
      }, 0);
      return false;
    }
  }, [firstItemIndex, timelineItems, hasMore]);

  return (
    <>
      {/* <IonContent ref={ionContentRef} scrollY={false} fullscreen> */}
      <IonList
        style={{
          height: "100%",
        }}
      >
        <Virtuoso
          totalCount={timelineItems.length}
          data={timelineItems}
          firstItemIndex={firstItemIndex}
          initialTopMostItemIndex={timelineItems.length - 1}
          startReached={prependItems}
          isScrolling={setIsScrolling}
          overscan={100}
          itemContent={(index, item) => {
            return (
              <>
                <FeedItemContainer {...item} />
              </>
            );
          }}
        />
      </IonList>
      {/* <PostContainer
          // chatModalRef={chatModalRef}
          journalModalRef={journalModalRef}
        ></PostContainer> */}
      {/* <PostJournalContainer
          pageRef={pageRef}
          journalModalRef={journalModalRef}
          isScrolling={isScrolling}
        ></PostJournalContainer> */}
      {/* </IonContent> */}
    </>
  );
};
