import { useRef } from "react";
import { PostModalPresenter } from "./PostModalPresenter";

type Props = {
  modalRef: React.RefObject<HTMLIonModalElement>;
};

export function PostModalContainer({ modalRef }: Props) {
  return <PostModalPresenter modalRef={modalRef} />;
}
