import { useLocation } from "react-router";
import { ResetPasswordPresenter } from "./ResetPasswordPresenter";
import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseResetPassword } from "./useResetPassword";
import { FirebaseError } from "firebase/app";
import { z } from "zod";
import { confirmPasswordResetFormSchema } from "../../../../../../sharedEntities";

export function ResetPasswordContainer() {
  type FormType = z.infer<typeof confirmPasswordResetFormSchema>;

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { mutate, isSuccess, isPending, isError, error } = UseResetPassword(
    query.get("oobCode")
  );
  const rhfMethods = useForm<FormType>({
    resolver: zodResolver(confirmPasswordResetFormSchema),
  });
  const onSubmitFunction: SubmitHandler<FormType> = (data, e) => {
    e?.preventDefault();
    mutate(rhfMethods.watch());
  };

  return (
    <ResetPasswordPresenter
      rhfMethods={rhfMethods}
      onSubmitFunction={onSubmitFunction}
      isSuccess={isSuccess}
      isPending={isPending}
      isError={isError}
      error={
        error instanceof FirebaseError || error instanceof Error
          ? error
          : undefined
      }
    />
  );
}
