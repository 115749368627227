import {
  IonCard,
  IonCardHeader,
  IonContent,
  IonItem,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { RequestToJoinTheGroupContainer } from "../feature/requestToJoinTheGroup/RequestToJoinTheGroupContainer";

type Props = {
  data:
  | { result: true; validGId: string; validInvitationCode: string }
  | { result: false };
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  error: Error | FirebaseError | undefined;
};
export function VerifyGroupInvitationPresenter({
  data,
  isSuccess,
  isLoading,
  isError,
  error,
}: Props) {
  return (
    <IonPage>
      <IonContent>
        {isLoading ? <IonSpinner></IonSpinner> : null}
        {isSuccess && data.result ? (
          <RequestToJoinTheGroupContainer
            groupId={data.validGId}
            invitationCode={data.validInvitationCode}
          ></RequestToJoinTheGroupContainer>
        ) : (
          null
        )}

        {isError && error ? <div>{error.message}</div> : null}
      </IonContent>
    </IonPage>
  );
}
