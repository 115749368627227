import Avatar from "boring-avatars";
import { UseAvatarSeedString } from "../../globalHook/useAvatarSeedString";

type Props = {
  size: string;
  uid: string;
};
export function AvatarWithSeed({ size, uid }: Props) {
  const { data, isSuccess } = UseAvatarSeedString(uid);

  return (
    <Avatar
      size={size}
      name={isSuccess ? data : "loading"}
      variant="beam"
    ></Avatar>
  );
}
