import {
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonNote,
  IonButton,
} from "@ionic/react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import {
  Controller,
  UseFormReturn,
  useFormContext,
  useFormState,
} from "react-hook-form";
import { useReducer, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  initialPasswordVisible: boolean;
  hasHelperText: boolean;
  validateErrorMessage: boolean;
};

export const PasswordInput = ({
  initialPasswordVisible,
  hasHelperText,
  validateErrorMessage,
}: Props) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const [isPasswordVisible, setIsPasswordVisible] = useState(initialPasswordVisible)
  console.log("render")

  return (
    <IonItem
      className={errors.password && validateErrorMessage ? "ion-invalid" : ""}
    >
      <Controller
        name="password"
        render={({ field }) => {
          return (
            <>
              <IonInput
                onIonInput={(e) => {
                  console.log("onInput")
                  field.onChange(e.detail.value);
                }}

                placeholder="password"
                type={isPasswordVisible ? "text" : "password"}
                autocomplete="current-password"
                label="password"
                helperText={
                  hasHelperText
                    ? "password must contain at least 8 characters"
                    : ""
                }
                clearOnEdit={false}
                {...field}
              ></IonInput>
            </>
          );
        }}
      ></Controller>

      <IonButton onClick={() => {
        setIsPasswordVisible(!isPasswordVisible)
      }} fill="clear" aria-label="passwordVisible">
        {isPasswordVisible ? (
          <IonIcon icon={eyeOutline} slot="end" aria-hidden></IonIcon>
        ) : (
          <IonIcon icon={eyeOffOutline} slot="end" aria-hidden></IonIcon>
        )}
      </IonButton>

      {errors.password && validateErrorMessage ? (
        <IonNote slot="error">
          <ErrorMessage errors={errors} name="password"></ErrorMessage>
        </IonNote>
      ) : null}
    </IonItem>
  );
};
