import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { CardWithForm } from "../../../../../Home/ui/cardWithForm.tsx/CardWithForm";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { FirebaseError } from "firebase/app";
import { ErrorMessage } from "@hookform/error-message";

type Props = {
  rhfMethods: UseFormReturn<any>;
  onSubmitFunction: SubmitHandler<any>;
  groupId: string;
  invitationCode: string;
  isSuccess: boolean;
  isPending: boolean;
  isError: boolean;
  error: Error | FirebaseError | undefined;
};

export function RequestToJoinTheGroupPresenter({
  rhfMethods,
  onSubmitFunction,
  groupId,
  invitationCode,
  isSuccess,
  isError,
  isPending,
  error,
}: Props) {
  console.log(rhfMethods.watch());
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>join the group</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <form onSubmit={rhfMethods.handleSubmit(onSubmitFunction)}>
          <input
            hidden
            value={groupId}
            {...rhfMethods.register("groupId")}
          ></input>
          <input
            hidden
            value={invitationCode}
            {...rhfMethods.register("invitationCode")}
          ></input>
          <IonButton type="submit" expand="block" disabled={isSuccess}>
            {isPending ? (
              <IonSpinner></IonSpinner>
            ) : isSuccess ? (
              "done"
            ) : (
              "Request to join the group"
            )}
          </IonButton>
          {isSuccess ? (
            <IonButton expand="block" routerLink="/home">
              Go to home
            </IonButton>
          ) : null}
        </form>
        <ErrorMessage
          errors={rhfMethods.formState.errors}
          name="groupId"
          render={({ message }) => (
            <IonItem color={"light"}>
              <IonText>
                <small>{message}</small>
              </IonText>
            </IonItem>
          )}
        />
        <ErrorMessage
          errors={rhfMethods.formState.errors}
          name="invitationCode"
          render={({ message }) => (
            <IonItem color={"light"}>
              <IonText>
                <small>{message}</small>
              </IonText>
            </IonItem>
          )}
        />
      </IonCardContent>
    </IonCard>
  );
}
