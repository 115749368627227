import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { addDoc, collection, doc } from "firebase/firestore";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { CommentType, UnstoredCommentType, unstoredCommentSchema } from "../../../../../../sharedEntities/commentType";

import { UseCurrentPostId } from "../../../../../globalHook/useCurrentPostId";
import { UseUserInfo } from "../../../../../globalHook/UseUserInfo";
import { fullyRegisteredUserSchema } from "../../../../../../sharedEntities";

export function UseCommentPost() {
    const { data: currentUser } = UseUserInfo()
    const { data: db } = UseFirestoreInitialization()
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: currentPostId } = UseCurrentPostId()

    const [isInitialFetch, setIsInitialFetch] = useState<boolean>(true);
    const queryClient = useQueryClient()

    async function postComment(formData: { message: string }) {
        if (!currentUser) throw new Error('currentUser error')
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')
        if (!currentPostId) throw new Error('currentPostId error')

        const commentsCollectionRef = collection(db, "version/v1/groups", currentGroupId, "posts", currentPostId, "comments").withConverter(firestoreConverter(unstoredCommentSchema))
        const currentUserDocRef = doc(db, "version/v1/users", currentUser.uid).withConverter(firestoreConverter(fullyRegisteredUserSchema))

        const commentData: UnstoredCommentType = {
            title: "",
            subtitle: "",
            message: formData.message,
            author: currentUserDocRef,
            visible: ["everyoneInGroups"]
        }
        return await addDoc(commentsCollectionRef, commentData)

    }

    return useMutation({ mutationFn: postComment })
}