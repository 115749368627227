import { z } from "zod";
import { firestoreTimestampSchema } from "./firestoreSchema";

export const unstoredNotificationSettingSchema = z.object({
    allowNotification: z.boolean(),
    notificationType: z.union([z.literal("everyNewMessage"), z.literal("justAtMentions"), z.literal("nothing")]),
})

export type UnstoredNotificationSettingType = z.infer<typeof unstoredNotificationSettingSchema>

export const storedNotificationSettingSchema = unstoredNotificationSettingSchema.extend({
    createdAt: firestoreTimestampSchema,
    updatedAt: firestoreTimestampSchema
})

export type StoredNotificationType = z.infer<typeof storedNotificationSettingSchema>

export type NotificationSettingType = UnstoredNotificationSettingType | StoredNotificationType

