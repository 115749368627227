import { CommentType } from "../../../../../../../../sharedEntities/commentType";
import { CommentItemPresenter } from "./CommentItemPresenter";
import { CommentItemType } from "./type";

export type Props = {
  commentItemData: CommentItemType;
};
export function CommentItemContainer({ commentItemData }: Props) {
  return <CommentItemPresenter {...commentItemData} />;
}
