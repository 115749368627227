import {
  IonRow,
  IonCol,
  IonChip,
  IonIcon,
  IonLabel,
  IonSkeletonText,
} from "@ionic/react";
import { chatboxEllipsesOutline } from "ionicons/icons";
import { AvatarWithSeed } from "../../../../../../../globalUi/avatar/AvatarWithSeed";
import { AvatarPlaceholder } from "../../../../../../../globalUi/avatar/AvatarPlaceholder";

export function CommentButtonPlaceholder() {
  return (
    <IonRow class="ion-align-items-center ion-justify-content-end">
      <IonCol size="auto">
        <IonChip>
          <IonIcon icon={chatboxEllipsesOutline} />
          <IonLabel>
            <IonSkeletonText></IonSkeletonText>
          </IonLabel>
        </IonChip>
      </IonCol>
    </IonRow>
  );
}
