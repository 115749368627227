import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { UseFirestoreInitialization } from "../../../../../globalHook/UseFirestoreInitialization";
import { UseValidGroupId } from "../../hook/UseValidGroupId";
import { firestoreConverter } from "../../../../../util/firestoreConverter";
import { GroupType, FullyRegisteredUserType, fullyRegisteredUserSchema, storedGroupSchema } from "../../../../../../sharedEntities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UseCurrentGroupId } from "../../../../../globalHook/useCurrentGroupId";

export function UseMemberApproval() {
    const { data: currentGroupId } = UseCurrentGroupId()
    const { data: db } = UseFirestoreInitialization()

    const queryClient = useQueryClient()

    async function approveMember(formData: { uid: string }) {
        if (!db) throw new Error('db error')
        if (!currentGroupId) throw new Error('currentGroupId error')
        const userDocRef = doc(db, "version/v1/users", formData.uid).withConverter(firestoreConverter(fullyRegisteredUserSchema))
        const userDoc = await getDoc(userDocRef)
        if (!userDoc.exists()) throw new Error("this user does not exist")

        const groupDocRef = doc(db, "version/v1/groups", currentGroupId).withConverter(firestoreConverter(storedGroupSchema))
        const groupDoc = await getDoc(groupDocRef)

        if (!groupDoc.exists()) throw new Error("this group does not exist")

        return await updateDoc(groupDocRef, { member: arrayUnion(userDocRef), pendingMember: arrayRemove(userDocRef) })
    }

    return useMutation({
        mutationFn: approveMember, onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["groupMembersInfo"] })
        }
    })
}