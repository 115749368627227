import { IonItem, IonLabel, IonInput, IonNote } from "@ionic/react";
import {
  Controller,
  UseFormReturn,
  useFormContext,
  useFormState,
} from "react-hook-form";
import { forwardRef } from "react";
import { ErrorMessage } from "@hookform/error-message";

type Props = React.ComponentProps<typeof IonInput> & {};

export const EmailInput = ({}: Props) => {
  const { register, control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <IonItem className={errors.email ? "ion-invalid" : ""}>
      <Controller
        name={"email"}
        render={({ field }) => (
          <IonInput
            onIonChange={(e) => {
              field.onChange(e.detail.value);
            }}
            placeholder="email"
            type="email"
            autocomplete="email"
            label="email"
            {...field}
          ></IonInput>
        )}
      ></Controller>
      {errors.email ? (
        <IonNote slot="error">
          <ErrorMessage errors={errors} name="email"></ErrorMessage>
        </IonNote>
      ) : null}
    </IonItem>
  );
};
