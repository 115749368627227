import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonLabel,
  IonCol,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonListHeader,
  IonCardSubtitle,
  IonBackdrop,
  IonItem,
} from "@ionic/react";
import {
  add,
  addCircle,
  addCircleOutline,
  chatbox,
  close,
} from "ionicons/icons";
import { AvatarWithSeed } from "../../../../../globalUi/avatar/AvatarWithSeed";
import "./PostPresenter.css";
import { PostModalFabContainer } from "./ui/PostModalFab/PostModalFabContainer";
import { PostModalContainer } from "./ui/PostModal/PostModalContainer";

type Props = {
  modalRef: React.RefObject<HTMLIonModalElement>;
  openModal: () => void;
  closeModal: () => void;
  // openChatModal: () => void;
  // closeChatModal: () => void;
  openJournalModal: () => void;
  closeJournalModal: () => void;
};
export function PostPresenter({
  modalRef,
  openModal,
  closeModal,
  // openChatModal,
  // closeChatModal,
  openJournalModal,
}: Props) {
  return (
    <>
      <PostModalFabContainer modalRef={modalRef}></PostModalFabContainer>
      <PostModalContainer modalRef={modalRef}></PostModalContainer>
      {/* <IonModal
        handle={false}
        ref={modalRef}
        initialBreakpoint={0.6}
        breakpoints={[0, 0.6]}
        className="backdrop-blur"
        style={{ "--background": "transparent", "--box-shadow": 0 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <IonGrid fixed>
          <IonRow class="ion-justify-content-end">
            <IonButton fill="clear" color={"dark"} onClick={() => closeModal()}>
              <IonIcon icon={close}></IonIcon>
            </IonButton>
          </IonRow>
          <IonRow
            class="ion-align-items-end ion-padding"
            onClick={() => {
              // openChatModal();
              closeModal();
            }}
          >
            <IonLabel>
              <h1>💬 Chat</h1>
              <h3>Chat with your member.</h3>
            </IonLabel>
          </IonRow>
          <IonRow
            class="ion-align-items-end ion-padding"
            onClick={() => {
              openJournalModal();
              closeModal();
            }}
          >
            <IonLabel>
              <h1>📓 Journal</h1>
              <h3>Record anything for your member or yourself.</h3>
            </IonLabel>
          </IonRow>
          <IonRow
            class="ion-align-items-end ion-padding"
            onClick={() => {
              closeModal();
            }}
          >
            <IonLabel>
              <h1>✋ Proposal</h1>
              <h3>Propose events, dinner today, rule of lives, etc.</h3>
            </IonLabel>
          </IonRow>
          <IonRow
            class="ion-align-items-end ion-padding"
            onClick={() => {
              closeModal();
            }}
          >
            <IonLabel>
              <h1>📣 Announce</h1>
              <h3>Announce something</h3>
            </IonLabel>
          </IonRow>
        </IonGrid>

        <IonContent
          class="ion-padding"
          style={{
            "--background": "transparent",
            backgroundFilter: "blur(50px)",
          }}
        >
          <IonGrid fixed>
            <IonRow>
              <h1>Post Type</h1>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonCard class="ion-padding ion-text-center ion-no-margin">
                  <IonCardTitle>✉️</IonCardTitle>
                  <IonCardTitle>Message</IonCardTitle>
                  <IonCardSubtitle>Normal message like chat.</IonCardSubtitle>
                </IonCard>
              </IonCol>
              <IonCol size="6">
                <IonCard class="ion-padding ion-text-center ion-no-margin">
                  <IonCardTitle>📓</IonCardTitle>
                  <IonCardTitle>Journal</IonCardTitle>
                  <IonCardSubtitle>
                    Journal you and your partner
                  </IonCardSubtitle>
                </IonCard>
              </IonCol>
              <IonCol size="6">
                <IonCard class="ion-padding ion-text-center ion-no-margin">
                  <IonCardTitle>😘</IonCardTitle>
                  <IonCardTitle>Thank you</IonCardTitle>
                  <IonCardSubtitle>Send your thank you</IonCardSubtitle>
                </IonCard>
              </IonCol>
              <IonCol size="6">
                <IonCard class="ion-padding ion-text-center ion-no-margin">
                  <IonCardTitle>🗳</IonCardTitle>
                  <IonCardTitle>Proposal</IonCardTitle>
                  <IonCardSubtitle>
                    Events,Rules,Today's Dinner.
                  </IonCardSubtitle>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal> */}
    </>
  );
}
